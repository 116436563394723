.rd-custom-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-ring {
    // margin: auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 0.7em solid #1c2c4a;
    border-right: 0.7em solid #1c2c4a;
    border-bottom: 0.7em solid #1c2c4a;
    border-left: 0.7em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: 7em;
    height: 7em;

    &:after {
      border-radius: 50%;
      width: 7em;
      height: 7em;
    }
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
