.sr-detail {
  color: #1c1c1c;
  font-family: futuraBkBt;
  padding: 70px 0;

  &__body {
    padding: 0 15px;

    .sr-detail__title {
      padding: 15px 10px;

      h3 {
        font-size: 16px;
        font-family: futuraMdBt;
      }
    }

    .sr-detail__info {
      display: flex;
      align-items: center;
      color: #1c2c4a;
      padding: 15px;

      &:last-child {
        align-items: flex-start;

        .sr-detail__info {
          &-date {
            margin-top: 7px;
          }

          &-icon-circle {
            margin-top: 7px;
          }
        }
      }

      &-date {
        flex: 0.17;
        text-align: right;
      }

      &-icon {
        flex: 0.13;
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: all 0.2s;

        &-circle {
          z-index: 1;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #cce6ed;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 15px;
            height: 15px;
          }
        }

        .line {
          position: absolute;
          // top: -25px;
          top: 0;
          z-index: -1;
          width: 3px;
          height: 60px;
          background-color: #dfe3e4;
        }
      }

      &-status {
        flex: 0.7;
        margin-top: 0;
        padding: 12px 17px;

        span {
          font-family: futuraBkBt;
          color: #1c1c1c;
        }
      }

      &--active {
        .sr-detail__info-icon {
          &-circle {
            width: 25px;
            height: 25px;
            background-color: #4d71b6;
          }

          .line {
            height: 80px;
            z-index: 1;
            background-color: #4d71b6;
          }
        }

        .sr-detail__info-status {
          font-family: futuraMdBt;
        }
      }
    }
  }
}
