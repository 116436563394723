a {
  color: black !important;
}

.pb60 {
  padding-bottom: 60px;
}

.am-modal-content {
  text-align: unset !important;
  background: #fdfdfd !important;
  border-radius: 10px 10px 0 0;
}

.bottom-navigation-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: #fff;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* padding: 10px; */
}

.bottom-navigation-wrap:before {
  /* border-top: 1px solid #bfbfbf; */
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  box-sizing: border-box;
  font-size: 2.66667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bootomBtn {
  background: none;
  border: 2px solid var(--lightBlue);
  background-color: var(--lightBlue);
  border-radius: 200px;
  padding: 5px 30px;
  outline: none;
  box-shadow: 2px 2px 8px #cce6ed;
  color: var(--black);
}

.gm-style-mtc,
.gm-svpc,
.gmnoprint {
  display: none;
}

.co-living-detail {
  padding-top: 55px;
}

.map-box-new {
  width: 100%;
  overflow: hidden;
}
