@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;800;900&display=swap");
@font-face {
  font-family: "bellazio";
  src: url("./app/fonts/Bellazio.ttf");
  font-weight: normal/bold/inherit;
  font-display: block;
}
@font-face {
  font-family: "futuraBkBt";
  src: url("./app/fonts/Futura\ Bk\ BT\ Book.ttf");
  font-weight: normal/bold/inherit;
  font-display: block;
}
@font-face {
  font-family: "futuraMdBt";
  src: url("./app/fonts/Futura\ Md\ BT\ Bold.ttf");
  font-weight: normal/bold/inherit;
  font-display: block;
}
@font-face {
  font-family: "futuraMdItalic";
  src: url("./app/fonts/Futura\ Md\ BT\ Medium\ Italic.ttf");
  font-weight: normal/bold/inherit;
  font-display: block;
}
.my-custom-font {
  font-family: "bellazio", "futuraBkBt", "futuraMdBt", "futuraMdItalic";
}
input,
textarea {
  font-family: futuraBkBt !important;
}
:root {
  --white: #ffffff;
  --smoke: #e5e5e5;
  --smokeWhite: #e8e8e8;
  --lightBlue: #cce6ed;
  --darkBlue: #4d71b6;
  --black: #1c1c1c;
  --lightBlack: #444549;
  --gray: #5e5e5e;
  --lightGray: #b7b7b7;
  --darkGreen: #1dac97;
  --red: #e04025;
  --light-pink: #f8d7da;
  --midGray: #a2a2a2;
  --lightPeach: #f7a491;
  --xyz: #1c2c4a;
}
/* * {
  font-family: futuraBkBt, Montserrat;
} */
a {
  /* color: var(--black) !important; */
  text-decoration: none !important;
}
.text-darkBlue {
  color: var(--darkBlue);
}
.text-xyz {
  color: var(--xyz);
}
.text-black {
  color: var(--black);
}
.text-white {
  color: var(--white);
}
.text-lightBlack {
  color: var(--lightBlack);
}
.text-gray {
  color: var(--gray);
}
.background_colour_primary {
  background: var(--lightBlue);
  padding: 4px;
}
.background_colour_lightBlack {
  background: var(--lightBlack);
}
.badge_colour_primary {
  background: var(--lightBlue);
  padding: 4px;
}
.border-radius-2 {
  border-radius: 2px;
}
.borderRadius-10 {
  border-radius: 10px;
}
.borderRadius-15 {
  border-radius: 15px;
}
.w-90 {
  width: 90%;
}
.w-70 {
  width: 70%;
}
.blur {
  opacity: 0.9;
  box-shadow: 0px 6.2px 13.1px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(1.55px);
}
.btn-primary {
  border: 2px solid var(--lightBlue);
  background-color: var(--lightBlue);
  border-radius: 200px;
  padding: 10px 35px;
  outline: none;
  box-shadow: 2px 2px 8px var(--lightBlue);
  font-family: futuraMdBt;
  text-transform: inherit;
}
.btn-secondry {
  border: 2px solid var(--darkBlue);
  background-color: var(--darkBlue);
  border-radius: 200px;
  padding: 10px 35px;
  outline: none;
  box-shadow: 2px 2px 8px var(--darkBlue);
  font-family: futuraMdBt;
  text-transform: inherit;
}
.pl-5 {
  padding-left: 10px;
}
.blog_body {
  /* padding-top: 70px; */
  width: 100%;
  height: 100%;
}
.card {
  background: var(--white);
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1);
  border: none !important;
  border-radius: 15px !important;
  overflow: hidden;
}
.smartListingBadge {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  background: var(--lightBlue);
  padding: 5px 10px;
  border-radius: 10px 0px 10px;
}
.futuraNN-12 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: var(--xyz);
}
.futuraNN-10 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
}
.futuraBold14 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
}
.futuraBold18 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: var(--black);
}
.futuraN700-14 {
  font-family: futuraMdBt !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}
.futuraN700-16 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}
.futuraN700-20 {
  font-family: futuraMdBt !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}
.futuraN700-14 button {
  text-transform: inherit !important;
  font-family: inherit !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
}

.futuraNBold-16 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.futuraNBold-30 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
}
.futuraNBold-35 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
}
.futuraN5-14 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--xyz);
}
.futura-18 {
  font-family: futuraMdBt;
  font-size: 18px;
  line-height: 130%;
  /* or 23px */

  text-align: center;
}
.futuraN700-17 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 145.9%;
}
.futuraBkBtN-16 {
  font-family: futuraBkBt;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
}
.futuraBkBtN-14 {
  font-family: futuraBkBt;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.futuraBkBtN-9 {
  font-family: futuraBkBt;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
}
.futuraBkBtN-12 {
  font-family: futuraBkBt;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}
.mobileBannerText {
  color: var(--white);
}
.montserratN900-45 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 45px;
}
.montserratN900-75 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 75px;
}
.montserratN900-22 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
}
.montserratNB-16 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
}
.montserratN400-16 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.montserratN800-20 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  /* line-height: 145.9%; */
  /* or 29px */
  text-align: center;
}


.publishBtn {
  background: none !important;
  border: 3px solid var(--darkBlue) !important;
  border-radius: 999px !important;
  padding: 10px 35px !important;
  outline: none !important;
  color: #1c2c4a !important;
  text-transform: inherit !important;
  font-family: futuraMdBt !important;
}
.bookN {
  border: 2px solid var(--lightBlue) !important;
  background-color: var(--lightBlue) !important;
  border-radius: 200px !important;
  padding: 10px 35px !important;
  outline: none !important;
  box-shadow: 2px 2px 8px #cce6ed !important;
  font-family: futuraMdBt !important;
  text-transform: inherit !important;
  color: var(--black) !important;
}
.bookN:disabled {
  opacity: 0.5;
  box-shadow: none !important;
}
.book-danger {
  border: 2px solid var(--red) !important;
  background-color: var(--white) !important;
  color: var(--red) !important;
  border-radius: 200px !important;
  padding: 10px 35px !important;
  font-family: futuraMdBt !important;
  outline: none !important;
  text-transform: inherit !important;
  box-shadow: none !important;
}
.book-danger:disabled {
  opacity: 0.5;
}
ul {
  list-style: none;
}
.inputBorder {
  background: var(--white) !important;
  border: 1px solid var(--midGray) !important;
  box-sizing: border-box;
  border-radius: 11px !important;
}
.text-xyz {
  color: var(--xyz);
}
.montserratN900-22 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 145.9%;
}
.montserratNB-18 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 145.9%;
}
.montserratNB-16 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 145.9%;
}
.montserratNB-14 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145.9%;
}
.futuraN700-18 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 145.9%;
}
.futuraMdBtNB-14 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 145.9%;
}
.futuraBkBtN-14 {
  font-family: futuraBkBt;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.futura-14 {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
}
.futura-12 {
  font-family: futuraMdBt;
  font-size: 12px;
  line-height: 130%;
}
.icon {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
.icon.icon-100 {
  height: 100px;
  width: 100px;
  line-height: 100px;
}
.icon.icon-50 {
  height: 50px;
  width: 50px;
  line-height: 100px;
}
.background {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.backgroudLightBlue {
  height: auto;
  background-image: url("./app/images/nameBackBlue.svg");
}
.backgroudLightBlueAmen {
  height: auto;
  background-image: url("./app/images/amenityBackBlue.png");
}
.grid-container {
  display: grid;
  grid-column-gap: 2px;
  grid-row-gap: 5px;
  grid-template-columns: auto auto auto;
  /* padding: 10px; */
  position: relative;
  width: 100%;
}
.backIcon {
  position: absolute;
  left: 14px;
  top: 0px;
  z-index: 2;
}
.blurIcon {
  /* width: 40px;
    height: 40px; */
  border-radius: 20px;
  padding: 7px 10px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  position: absolute;
  right: 20px;
  bottom: 14px;
  color: var(--white);
  line-height: 2;
  vertical-align: middle;
}
.bookN-s-view-btn {
  background: none !important;
  border: 2px solid var(--lightBlue) !important;
  background-color: var(--lightBlue) !important;
  border-radius: 200px !important;
  padding: 3px 20px !important;
  outline: none !important;
  font-size: 12px !important;
  box-shadow: 2px 2px 8px #cce6ed !important;
  color: var(--black) !important;
  text-transform: inherit !important;
  font-family: futuraBkBt !important;
  font-weight: bold !important;
}
.bookN-s {
  background: none !important;
  border: 2px solid var(--lightBlue) !important;
  background-color: var(--lightBlue) !important;
  border-radius: 200px !important;
  padding: 5px 15px !important;
  outline: none !important;
  box-shadow: 2px 2px 8px #cce6ed !important;
  color: var(--black) !important;
  text-transform: inherit !important;
  font-family: futuraBkBt !important;
  font-weight: bold !important;
}
.filter-Apply {
  background: none;
  border: 2px solid var(--lightBlue);
  background-color: var(--lightBlue);
}
.grid-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
}
.grid-item-0 {
  position: relative;
  grid-column: 1 / 5;
  grid-row: 1 / 4;
  height: 240px;
  /* width: 250px; */
  width: 100%;
}
.grid-item-0 img {
  border-radius: 100px 0 0 0;
}
.grid-item-n {
  grid-column: 5 / 5;
  height: 240px;
  /* width: 121px; */
  width: 100%;
  max-width: 150px;
}
/* .grid-item-n::before {
  display: block;
  content: "";
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 50%;
  height: 100%;
  width: 100%;
  background-color: rgba(28, 44, 74, 0.25);
} */
.grid-item-1 {
  grid-column: 5 / 5;
  grid-row: 2/ 2;
  height: 118px;
  /* width: 121px; */
  width: 100%;
  max-width: 150px;
}
.grid-item-2 {
  grid-column: 5 / 5;
  grid-row: 1/ 2;
  height: 117px;
  /* width: 121px; */
  width: 100%;
  max-width: 150px;
}

.float-left {
  float: left;
}
.amenities > .amenDiv {
  float: left;
}
.filter-backdrop {
  background: rgb(0 0 0/85%);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
}
.frame {
  width: 100%;
  height: 300px;
}
.filter-Apply {
  background: none !important;
  border: 2px solid var(--lightBlue) !important;
  background-color: var(--lightBlue) !important;
  border-radius: 220px !important;
  padding: 10px 50px !important;
  outline: none !important;
  color: var(--black) !important;
  box-shadow: 2px 2px 8px #cce6ed !important;
  font-family: futuraMdbt, sans-serif !important;
  text-transform: inherit !important;
}
.filter-Reset {
  background-color: var(--white) !important;
  border: 2px solid var(--lightBlue) !important;
  box-shadow: 2px 2px 8px #cce6ed !important;
  border-radius: 220px !important;
  padding: 10px 50px !important;
  outline: none !important;
  color: var(--black) !important;
  font-family: futuraMdbt, sans-serif !important;
  text-transform: inherit !important;
}
.filter-Reset:disabled {
  opacity: 0.6;
  box-shadow: none !important;
  /* background-color: #e6f1f4 !important;
  color: rgb(28 28 28 / 60%) !important;
  border: 2px solid #ccd8db !important;
  box-shadow: 2px 2px 8px rgb(128 128 128 / 0%) !important; */
}
.step-one-btn {
  background-color: var(--white) !important;
  border: 2px solid var(--lightBlue) !important;
  box-shadow: 2px 2px 8px #cce6ed !important;
  border-radius: 220px !important;
  padding: 5px 15px !important;
  outline: none !important;
  font-size: 14px;
  color: var(--black) !important;
  font-family: futuraBkbt, sans-serif !important;
  text-transform: inherit !important;
}

.filter-Apply {
  background: none !important;
  border: 2px solid var(--lightBlue) !important;
  background-color: var(--lightBlue) !important;
  border-radius: 220px !important;
  padding: 10px 50px !important;
  outline: none !important;
  color: var(--black) !important;
  text-transform: inherit !important;
}
.filter-Reset {
  background: none !important;
  border: 2px solid var(--lightBlue) !important;
  background-color: var(--white) !important;
  border-radius: 220px !important;
  padding: 10px 50px !important;
  outline: none !important;
  color: var(--black) !important;
  text-transform: inherit !important;
}
.text-darkBlue {
  color: var(--darkBlue) !important;
}
.bookDanger {
  background: none !important;
  border: 2px solid var(--red) !important;
  background-color: var(--white) !important;
  border-radius: 20px !important;
  padding: 5px 25px !important;
  outline: none !important;
  color: var(--red) !important;
  text-transform: inherit !important;
}
.inputBorder {
  background: var(--white) !important;
  border: 1px solid var(--midGray) !important;
  box-sizing: border-box;
  border-radius: 12px !important;
  height: fit-content;
}
.close-btn {
  background-color: inherit;
  border: none;
  outline: none;
  padding: 5px;
  color: #252436;
  transition: all 0.1s;
}
.close-btn .MuiSvgIcon-root {
  color: #29283d !important;
}
.close-btn:active,
.close-btn:focus {
  color: #3b5998;
}
.close-btn:focus .MuiSvgIcon-root {
  color: #3b5998 !important;
}
.share-input {
  width: 100%;
  border: 1px solid #d3d3d3;
  outline: none;
  background-color: #ebebeb;
  padding: 5px;
  overflow: hidden;
  overflow-x: auto;
  transition: all 0.2s;
}
.rd-input {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 12px 16px;
  font-family: futuraBkBt;
  font-size: 16px;
  margin-bottom: 10px;
  transition: all 0.2s;
}
.rd-input:active,
.rd-input:focus {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1) !important;
  border-color: transparent;
}
.custom-body .ant-picker {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  margin: 5px 0;
}
.custom-body .ant-picker:active,
.custom-body .ant-picker:focus {
  border-color: #40a9ff !important;
}
.custom-body .ant-picker-dropdown {
  z-index: 9999 !important;
}
.copy-btn {
  height: 41px;
  width: 41px;
  background-color: rgba(0, 0, 0, 0.05) !important;
  min-width: 0 !important;
  border-radius: 5px !important;
  border: 2px solid rgba(0, 0, 0, 0.1) !important;
  padding: 7px !important;
}
.copy-btn img {
  width: 30px;
}
.rd-stats p {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rd-stats p span {
  text-align: left;
}
.rd-stats p span:first-child {
  flex: 0.6;
}
.rd-stats p span:last-child {
  flex: 0.3;
}
.form-control {
  background: unset;
}
.rd-custom-input {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  padding: 12px 16px;
  margin: 7px 0;
  transition: all 0.2s;
}
.rd-custom-input:focus {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
  border-color: transparent;
}
.rd-custom-textarea {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  width: 100%;
  padding: 12px 16px;
  margin: 7px 0;
  transition: all 0.2s;
  resize: none;
}
.rd-custom-textarea:focus {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
  border-color: transparent;
}
.rd-custom-card {
  /* margin: 0 15px; */
  margin-top: 25px;
  padding: 20px 15px;
  box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.rd-pwa-install {
  font-family: futuraBkBt !important;
  font-weight: bold !important;
  background-color: var(--lightBlue) !important;
  border-radius: 999px !important;
  padding: 10px 30px !important;
  outline: none !important;
  color: var(--xyz) !important;
  text-transform: inherit !important;
}
