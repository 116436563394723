.coliving-booking__body {
  font-family: futuraBkBt;
  padding: 75px 15px;
  padding-bottom: 25px;
  color: #1c1c1c;

  .custom-button {
    font-family: futuraMdBt !important;
    color: #1c2c4a !important;
    padding: 3px 15px !important;
    font-size: 12px !important;
  }

  .coliving-booking__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    .top--title {
      font-family: futuraMdBt;
      font-size: 16px;
    }

    .top--void {
      opacity: 0;
    }
  }

  .info-card {
    h4 {
      font-family: futuraMdBt;
      font-weight: bold;
      font-size: 16px;
      width: 275px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: futuraBkBt;
      font-weight: bold;
      color: #22215b;
      font-size: 18px;
    }

    .card-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card--footer {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-family: futuraBkBt;
      padding-top: 10px;

      .content {
        display: flex;
        align-items: center;
      }
    }
  }

  .summary-card {
    h4 {
      font-family: futuraMdBt;
      font-size: 16px;
      color: #1c2c4a;
    }

    .summaries {
      padding: 7px 0;

      .summary {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: futuraBkBt;

        .summary-date {
          color: #1c2c4a;
          font-weight: bold;
        }

        .summary-value {
          font-family: Montserrat;
          font-weight: bold;
          color: #1c2c4a;
        }
      }
    }

    .custom-btn {
      padding: 5px 17px !important;
      color: #1c2c4a !important;
      font-size: 12px !important;
    }
  }

  .coliving-booking__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;

    .bookDanger {
      color: var(--red) !important;
    }
  }
}

.ant-picker-dropdown {
  z-index: 999999999999999 !important;
}

.submit-notice {
  label {
    font-family: futuraBkBt !important;
  }

  .form-control {
    height: unset !important;
    width: 100% !important;
    padding: 12px 15px 12px 60px !important;
    border-radius: 15px !important;
  }

  .ant-picker-input {
    padding: 10px;
    border-radius: 15px;
    padding: 12px 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:focus-within {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
      border-color: transparent;
    }
  }

  .ant-picker {
    padding: 0 !important;
    width: 100% !important;
    border: none !important;
    box-shadow: none !important;
  }
}
