.nHeader {
    width: 100%;
    height: 290px;
    
    .nHeader_bg {
        width: 100%;
        height: 100%;
        background-image: url("../../images/headerBg.png");
        background-size: cover;
        background-repeat: no-repeat;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .nHeader_bg2 {
        width: 100%;
        height: 100%;
        background-image: url("../../images/hire2.jpg");
        background-size: cover;
        background-repeat: no-repeat;

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
.w_kit_a_W {
    width: -webkit-fill-available;
}
.section {
    width: 100%;
    text-align: center;
}