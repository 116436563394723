.chat-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  backdrop-filter: blur(10px);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  .chat-form__image {
    padding: 10px;
    margin-right: 5px;
    margin-left: -5px;
    // padding-right: 15px;
    // padding-left: 5px;
  }

  .chat-form__form {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 11px;
    padding: 10px;
    transition: all 0.2s;

    &:focus-within {
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1) !important;
      border-color: transparent;
    }

    textarea {
      padding: 3px 5px;
      flex: 1;
      background: none !important;
      border: none;
      outline: none;
      resize: none;
    }

    button {
      width: 40px !important;
      height: 40px !important;
      padding: 10px !important;
    }
  }
}
