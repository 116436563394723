.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 2;
  // background: white;
}

.screen-header {
  background: #f5fafb;
}

.room-detail {
  .img-back {
    display: none !important;
  }
}

.layoutBody {
  padding-top: 65px;
  width: 100%;

  .ant-select-selector {
    border-radius: 999px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }

  .container_ul {
    width: 100%;
    float: left;
    padding: 0;
    list-style-type: none;

    li {
      color: #5e5e5e;
      display: block;
      position: relative;
      float: left;
      margin-right: 1%;
      margin-bottom: 10px;

      span {
        border: 1px solid #cfcfcf;
        border-radius: 999px;
        padding: 5px 10px;
      }
    }
  }
}
