.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--black) !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: var(--black) !important;
  pointer-events: none;
}

.bookingCard-img {
  height: 100% !important;
  width: 100%;
  object-fit: cover !important;
  border-radius: 12px 0px 0px 12px;
}

.small_icon {
  width: 18px;
  height: 20px;
}

.booking-page .ant-tabs-tab {
  width: 100px !important;
  text-align: center !important;
  display: grid;
  font-family: futuraBkBt;
  place-items: center;
}

.view-btn {
  padding: 3px 20px !important;
}

.custom-subtab {
  font-family: futuraBkBt;
}

.custom-subtab .ant-tabs-nav-list {
  margin-top: -15px;
}

.custom-subtab .ant-tabs-tab,
.custom-subtab .ant-tabs-tab-active {
  /* height: 75px !important; */
  font-size: 14px !important;
}

.custom-subtab .ant-tabs-tab-active {
  font-weight: bold !important;
}

.custom-subtab .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 14px;
}

.my-booking__no-booking {
  padding: 20px 15px;
  color: #5e5e5e;
}

.my-booking__no-booking h3 {
  font-family: futuraMdBt;
  font-size: 16px;
  color: #1c2c4a;
  margin-bottom: 15px;
}

.my-booking__no-booking a {
  color: #1c2c4a !important;
  text-decoration: underline !important;
}
