a {
  text-decoration: none !important;
  color: inherit !important;
}

.chat-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 20px;
  transition: all 0.2s;

  &:hover {
    background-color: rgba(186, 207, 228, 0.2);
  }

  &:active {
    background-color: rgba(186, 207, 228, 0.35);
  }
}

.chat-user__avatar {
  width: 65px !important;
  height: 65px !important;

  // &.chat-user__avatar--unread {
  //   width: 55px !important;
  //   height: 55px !important;
  //   box-shadow: 0 0 1px 10px rgba(204, 230, 237, 0.5);
  // }
}

.chat-user__info {
  flex: 1;
  padding: 0 20px;

  h4 {
    font-family: futuraMdBt;
    font-size: 18px;
    margin-bottom: 2px;
    color: #1c2c4a;
  }

  p {
    color: #1c2c4a;
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 125px;
  }

  div {
    font-size: 16px;
    width: 100px;
    color: #5e5e5e;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.chat-user__things {
  p {
    color: #5e5e5e;
  }

  .chat-user__unread-count {
    color: #1c2c4a;
    font-family: futuraBkBt;
    width: 25px;
    height: 25px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 999px;
    background-color: rgb(204, 230, 237);
    box-shadow: 0 0 1px 5px rgba(204, 230, 237, 0.35);
    display: grid;
    place-items: center;
  }
}
