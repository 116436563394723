.message {
  display: flex;
  font-family: futuraBkBt;
  padding: 10px 0;

  .message__avatar {
    padding-right: 12px;

    .MuiAvatar-root {
      height: 50px !important;
      width: 50px !important;
      object-fit: contain !important;
    }
  }

  .message__message {
    .message__info {
      margin-bottom: 5px;

      span {
        color: #5e5e5e;
        font-size: 12px;

        &.message__sender {
          font-family: futuraMdBt;
          font-size: 14px;
          font-weight: bold;
          color: #1c2c4a !important;
        }
      }
    }

    > p {
      word-break: break-word;
    }
  }

  &.message--sender {
    flex-direction: row-reverse;

    .message__avatar {
      padding-left: 12px;
    }

    .message__message {
      .message__info {
        margin-bottom: 5px;
        text-align: right;
  
        span {
          color: #5e5e5e;
          font-size: 12px;
  
          &.message__sender {
            font-family: futuraMdBt;
            font-size: 14px;
            font-weight: bold;
            color: #1c2c4a !important;
          }
        }
      }
  
      > p {
        background-color: aliceblue;
        word-break: break-word;
      overflow-x: hidden;
      border-style: groove;
      border-width: 1px 1px 1px 1px;
      content: '';
      bottom: 0;
      right: -10px;
      clear: both;
      border-radius: 10px;
      //border-top-right-radius: 0;
      margin-bottom: 2px;
      padding-inline: 15px;
    }
    
  }
    }

    &.message__receiver {
      font-family: futuraMdBt;
      font-size: 14px;
      font-weight: bold;
      color: #1c2c4a !important;
    }
  


> p {
  word-break: break-word;

}


&.message--receiver {


.message__avatar {
  padding-left: 12px;
}

.message__message {
  .message__info {
    margin-bottom: 5px;
    //text-align: right;

    span {
      color: #5e5e5e;
      font-size: 12px;

      &.message__receiver {
        font-family: futuraMdBt;
        font-size: 14px;
        font-weight: bold;
        color: #1c2c4a !important;
      }
    }
  }

  > p {
    background-color: lightcyan;
    word-break: break-word;
  overflow-x: hidden;
  border-style: groove;
  border-width: 1px 1px 1px 1px;
  content: '';
  bottom: 0;
  right: -10px;
  clear: both;
  border-radius: 10px;
  //border-bottom-right-radius: 0;
  margin-bottom: 2px;
  padding-inline: 15px;
  
}

}
}
}

