.gm-style-mtc,
.gm-svpc,
.gmnoprint {
  display: none;
}
.ant-steps-item-icon {
  background: var(--lightBlue) !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--xyz);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--xyz) !important;
}
.place-an-ad {
  * {
    font-family: futuraBkBt;
  }

  input,
  textarea {
    font-family: futuraMdBt !important;
    // font-weight: bold !important;
  }

  .map-container-post-ad {
    position: sticky;
    top: 55px;
    left: 0;
    z-index: 999;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  .ant-select-selector,
  .ant-select {
    border: none !important;
    padding: 0 !important;
  }
  .ant-select-auto-complete {
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    // border: 1px solid var(--midGray) !important;
    // border-radius: 11px !important;

    input {
      width: 100%;
    }
  }
}
