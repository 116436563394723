.hotel-booking__body {
  padding: 75px 15px;
  padding-bottom: 25px;
  color: #1c1c1c;

  .custom-button {
    font-family: futuraMdBt !important;
    color: #1c2c4a !important;
    padding: 3px 15px !important;
    font-size: 12px !important;
  }

  .hotel-booking__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;

    .top--title {
      font-family: futuraMdBt;
      font-size: 16px;
    }

    .top--void {
      opacity: 0;
    }
  }

  .info-card {
    .top-flex {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: #5e5e5e;
      font-family: futuraBkBt;
      padding: 5px;
      padding-bottom: 15px;

      div {
        flex: 0.5;

        h4 {
          font-family: futuraMdBt;
          font-weight: bold;
          font-size: 16px;
          width: 140px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          display: flex;
          flex-direction: column;

          .date {
            color: #1c1c1c;
            font-weight: bold;
          }
        }

        .booking-room {
          margin-top: -3px;
          font-size: 16px;
        }
      }
    }

    .card-btns {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        width: 125px;
      }
    }

    .card--footer {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      font-family: futuraBkBt;
      padding-top: 12px;

      p {
        font-family: Montserrat;
        font-weight: bold;
        color: #1c2c4a;
      }
    }
  }

  .summary-card {
    h4 {
      font-family: futuraMdBt;
      font-size: 16px;
      color: #1c2c4a;
    }

    .summaries {
      padding: 7px 0;

      .summary {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: futuraBkBt;

        .summary-date {
          color: #1c2c4a;
          font-weight: bold;
        }

        .summary-value {
          font-family: Montserrat;
          font-weight: bold;
          color: #1c2c4a;
        }
      }
    }

    .custom-btn {
      padding: 5px 17px !important;
      color: #1c2c4a !important;
      font-size: 12px !important;
    }
  }

  .cancellation-card {
    h4 {
      font-family: futuraMdBt;
      font-size: 16px;
      color: #1c2c4a;
    }

    p {
      color: #1c1c1c;
      font-family: futuraBkBt;
    }

    .custom-btn {
      padding: 5px 17px !important;
      font-size: 12px !important;
    }
  }
}
