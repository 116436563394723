html {
  width: 100%;
}

body {
  width: 100%;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #222222;
  /* background: url("../../img/background2.png") #f3f7fa fixed;
  background-position: center top;
  background-size: cover; */
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
.btn:last-child {
  margin-bottom: 0;
}

p:last-child {
  margin-bottom: 0;
}

.container {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.bg-dark {
  background-color: #2f2d51 !important;
}

.bg-none {
  background: transparent !important;
}

.link {
  color: #2f2d51;
}

.text-secondary {
  color: #999999 !important;
}

.text-danger {
  color: #ff97b5 !important;
}

.font-weight-medium {
  font-weight: 500;
}

.bg-info-gradient {
  background: #696cd4;
  background: -moz-linear-gradient(45deg, #696cd4 0%, #80d4ff 100%);
  background: -webkit-linear-gradient(45deg, #696cd4 0%, #80d4ff 100%);
  background: linear-gradient(45deg, #696cd4 0%, #80d4ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#696cd4', endColorstr='#80d4ff', GradientType=1);
}

.w-300 {
  max-width: 360px;
  width: 300px;
}

.bottom-center {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  width: auto;
  z-index: 999;
  margin: 0 auto;
}

.background {
  background-position: center center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.small-font {
  font-size: 14px;
  line-height: 22px;
}

.transition,
.header,
main,
.floating-form-group > .floating-label,
.floating-form-group .floating-input,
.filter,
.menu-push-content .main-menu,
.btn.colorsettings,
.sidebar-right {
  transition: all ease 0.5s;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  -ms-transition: all ease 0.5s;
}

.vm {
  vertical-align: middle;
}

.border-color,
table.no-border tfoot tr td,
.menu-active main {
  border-color: rgba(47, 45, 81, 0.05) !important;
}

.z-1 {
  z-index: 1;
}

/* map view */
.map-view-map {
  position: fixed;
  z-index: 0;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  border: 0;
}

.map-view {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 30px;
  left: 0;
}

.map-box {
  width: 100%;
  height: 120px;
  border-radius: 15px;
  overflow: hidden;
}

.top-5 {
  position: relative;
  top: -5px;
}

.top-30 {
  top: -30px;
  position: relative;
}

table.no-border tr td {
  border: 0;
}

table.no-border tfoot tr td {
  border-top: 2px solid #000;
}

.no-caret:after {
  display: none;
}

.dropdown-menu {
  border: 0;
  border-radius: 15px;
  padding: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu .dropdown-item {
  border-radius: 15px;
}

::-webkit-scrollbar {
  width: 0px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(97, 33, 210, 0.3);
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(97, 33, 210, 0.05);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 8px 15px;
  z-index: 2;
  backdrop-filter: blur(2px);
  background-color: rgb(0 0 0 / 15%);
}

.header .navbar-brand {
  padding: 0;
  vertical-align: -webkit-baseline-middle !important;
}

.header .navbar-brand > img {
  max-height: 44px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.header .navbar-brand h1 {
  font-size: 20px;
  color: #2f2d51;
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  margin-left: 10px;
}

.header.active {
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}

main {
  background: url("../../img/background2.png") #f3f7fa fixed;
  background-size: cover;
  padding-top: 60px;
  display: block;
  width: 100%;
  top: 0;
  padding-top: 60px;
  z-index: 1;
}

.logo-loading {
  text-align: center;
}

.logo-loading img {
  max-width: 100px;
  margin-bottom: 15px;
}

.logo-loading h3 {
  color: #2f2d51;
  font-size: 30px;
}

.loader-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-ellipsis div {
  position: absolute;
  top: 30px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #2f2d51;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loader-ellipsis1 0.6s infinite;
}

.loader-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loader-ellipsis2 0.6s infinite;
}

.loader-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loader-ellipsis2 0.6s infinite;
}

.loader-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loader-ellipsis3 0.6s infinite;
}

@keyframes loader-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes loader-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loader-display {
  position: fixed;
  top: 0;
  left: 0;
  background: url("../../img/background2.png") #f3f7fa fixed;
  background-position: center top;
  background-size: cover;
  z-index: 9999;
}

/*------  -------*/
.icon-size-16 {
  width: 18px;
}

.icon-size-12 {
  width: 12px;
}

.icon-size-24 {
  width: 24px;
}

.icon-size-32 {
  width: 32px;
}

.icon-size-40 {
  width: 40px;
}

.icon-size-48 {
  width: 48px;
}

.icon-size-96 {
  width: 96px;
}

.icon {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}

.icon > span {
  vertical-align: middle;
}

.icon.icon-44 {
  height: 44px;
  width: 44px;
  line-height: 40px;
}

.icon.icon-44 > span {
  font-size: 22px;
}

.icon.icon-100 {
  height: 100px;
  width: 100px;
  line-height: 100px;
}

.icon.icon-100 > span {
  font-size: 50px;
}

.icon.rounded {
  border-radius: 15px;
}

.text-dark svg path,
.text-dark svg polyline,
.text-dark svg line,
.text-dark svg rect,
.text-dark svg circle,
.text-dark svg polygon {
  stroke: #2f2d51 !important;
  fill: #2f2d51;
}

.text-white svg path,
.text-white svg polyline,
.text-white svg line,
.text-white svg rect,
.text-white svg circle,
.text-white svg polygon {
  stroke: #ffffff !important;
  fill: #fff;
}

.text-danger svg path,
.text-danger svg polyline,
.text-danger svg line,
.text-danger svg rect,
.text-danger svg circle,
.text-danger svg polygon {
  stroke: #ff97b5 !important;
  fill: #ff97b5;
}

.text-secondary svg path,
.text-secondary svg polyline,
.text-secondary svg line,
.text-secondary svg rect,
.text-secondary svg circle,
.text-secondary svg polygon {
  stroke: #999999 !important;
  fill: #999999;
}

.image-circle {
  text-align: center;
  width: 100%;
  padding: 20px 15px 40px 15px;
  margin-bottom: 20px;
  position: relative;
}

.image-circle > figure {
  height: 300px;
  width: 300px;
  display: inline-block;
  margin: 0 auto;
  border-radius: 150px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  vertical-align: top;
}

.image-circle > .icon {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto -40px auto;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  opacity: 0.35;
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #2f2d51;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.footer {
  padding: 15px 0px 25px 0;
  z-index: 0;
  position: relative;
}

/* login */
.login-box {
  max-width: 350px;
  margin: 0 auto;
}
.facebook-button {
  background-color: #3b5998;
  display: inline-flex;
  align-items: center;
  color: #f5f5f5;
  box-shadow: rgb(59 89 152) 0px 2px 2px 0px, rgb(59 89 152) 0px 0px 1px 0px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #3b5998;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
}
.facebook-button i {
  margin-right: 15px;
}

/* input fields */
input:focus,
select:focus,
textarea:focus,
.form-control:focus {
  outline: none;
  -webkit-outline: none;
  box-shadow: none !important;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  background: transparent !important;
}

.form-control {
  padding: 10px 15px;
  line-height: 24px;
  font-size: 15px;
  height: auto;
}

.form-control::placeholder {
  color: rgba(153, 153, 153, 0.4);
  font-weight: 400;
}

.floating-form-group {
  position: relative;
  padding-top: 15px;
  /* z-index: 1; */
}

.floating-form-group > .floating-label {
  position: absolute;
  left: 0;
  top: 22px;
  color: rgba(153, 153, 153, 0.4);
  /* me */
  /* z-index: -1; */
}

.floating-form-group .floating-input {
  background: transparent;
  border-radius: 0;
  border-width: 0px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.3);
  padding: 10px 0px;
  line-height: 24px;
  z-index: 2;
}

.floating-form-group .floating-input:focus,
.floating-form-group .floating-input:focus:active {
  border-bottom: 1px solid #ff97b5;
}

.floating-form-group .floating-input:focus + .floating-label,
.floating-form-group .floating-input:focus:active + .floating-label {
  color: #ff97b5;
  top: 0;
  font-size: 13px;
}

.floating-form-group.active > .floating-label {
  color: #999999;
  top: 0;
  font-size: 13px;
}

.search {
  border: 0;
  border-radius: 30px !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}

/* boxed checkbox */
.checkbox-boxed {
  display: none;
}

.checkbox-boxed + .checkbox-lable {
  cursor: pointer;
  background: none;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  color: #999999;
  margin-bottom: 0;
}

.checkbox-boxed + .checkbox-lable span {
  display: block;
}

.checkbox-boxed + .checkbox-lable .image-boxed {
  height: 100px;
  line-height: 70px;
  width: 70%;
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  display: block;
  /* background: #2F2D51; */
  margin: auto;
}

.checkbox-boxed + .checkbox-lable .p {
  padding: 5px 0;
  line-height: 16px;
  font-size: 12px;
  display: block;
  margin: 0;
}

/* .checkbox-boxed:checked + .checkbox-lable {
  background: #80D4FF;
  color: #ffffff;
} */

.checkbox-boxed:checked + .checkbox-lable .image-boxed {
  background: #2f2d51;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #ffffff !important;
  border-color: #17a2b8 !important;
  background-color: #80d4ff !important;
}

/* range picker slider */
.noUi-target {
  background: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid rgba(153, 153, 153, 0.2);
}

.noUi-target.noUi-horizontal {
  height: 10px;
}

.noUi-target .noUi-origin .noUi-handle {
  height: 30px;
  width: 30px;
  top: -11px;
  border: 0;
  box-shadow: 0 35x 7px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 7px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  border: 1px solid rgba(153, 153, 153, 0.2);
}

.noUi-target .noUi-origin .noUi-handle:before {
  display: none;
}

.noUi-target .noUi-origin .noUi-handle:after {
  display: none;
}

.noUi-target .noUi-connect {
  background: #80d4ff;
}

/* switch customized */
.switch-wrap {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
  width: 60px;
  height: 30px;
  border-radius: 15px;
}

.switch-wrap input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-wrap .switch {
  height: 100%;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  position: relative;
  width: 100%;
  border-radius: 15px;
}

.switch-wrap .switch::after {
  content: "";
  border-radius: 50%;
  background: #ccc;
  transition: 0.2s;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -ms-transition: 0.2s;
  position: absolute;
  left: 2px;
  top: 2px;
  height: 26px;
  width: 26px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.switch-wrap.switch-success input:checked + .switch {
  background-color: #6be3a2;
}

.switch-wrap.switch-info input:checked + .switch {
  background-color: #80d4ff;
}

.switch-wrap.switch-danger input:checked + .switch {
  background-color: #ff97b5;
}

.switch-wrap.switch-primary input:checked + .switch {
  background-color: #63abff;
}

.switch-wrap.switch-warning input:checked + .switch {
  background-color: #ffdd6d;
}

.switch-wrap input:checked + .switch::after {
  background-color: #ffffff;
  left: 32px;
}

.custom-control-input.switch-success:checked + .custom-control-label:before {
  background-color: #6be3a2;
  border-color: #6be3a2;
}

.custom-control-input.switch-info:checked + .custom-control-label:before {
  background-color: #80d4ff;
  border-color: #80d4ff;
}

.custom-control-input.switch-danger:checked + .custom-control-label:before {
  background-color: #ff97b5;
  border-color: #ff97b5;
}

.custom-control-input.switch-primary:checked + .custom-control-label:before {
  background-color: #63abff;
  border-color: #63abff;
}

.custom-control-input.switch-warning:checked + .custom-control-label:before {
  background-color: #ffdd6d;
  border-color: #ffdd6d;
}

.header-title {
  font-size: 15px;
  font-weight: 400;
}

.card {
  border: 0;
  border-radius: 15px !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  z-index: 1;
}

.card .card-header {
  border: 0;
  border-radius: 15px 15px 0 0;
  background: transparent;
  padding: 10px 15px;
}

.card .card-footer {
  border: 0;
  border-radius: 0 0 15px 15px;
  background: transparent;
  padding: 10px 15px;
}

.card .card-body-wrap {
  border: 1px dashed rgba(255, 255, 255, 0.5);
  margin: 5px;
  display: block;
  border-radius: 15px;
}

.card .card-body {
  border: 0;
  padding: 15px 15px;
}

.payment-card-large {
  width: 300px;
  min-width: 300px;
}

.filter {
  position: fixed;
  z-index: 999;
  width: 300px;
  right: -320px;
  max-height: 80%;
  overflow-y: auto;
  padding: 10px;
  top: 137px;
}

.filter > .filters-container {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  min-height: 100%;
  position: relative;
  padding: 25px !important;
}

.filter.active {
  right: 0px;
}

.filter-btn.close {
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 1;
}

.filter-backdrop {
  /* background: rgba(128, 212, 255, 0.25); */
  background: rgb(0 0 0 / 85%);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  display: none;
}

.swiper-products {
  margin-bottom: -10px;
}

.swiper-products .swiper-slide {
  width: auto;
  padding: 0 0px 10px 20px;
}

.swiper-products .swiper-slide:first-child {
  padding-left: 30px;
}

.swiper-products .swiper-slide:last-child {
  padding-right: 30px;
}

.product-card-large {
  width: 315px;
  height: auto;
}

.product-card-large .product-image-large {
  border-radius: 15px;
  overflow: hidden;
  height: 150px;
  width: 100%;
  display: block;
  position: relative;
}

.product-card-large .product-image-large .tag-images-count {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.product-card-large .product-image-large .tag-images-count span {
  margin-left: 5px;
}

.product-card-large .product-image-large .small-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.product-card-small .product-image-small {
  border-radius: 15px;
  overflow: hidden;
  height: 80px;
  width: 80px;
  display: block;
  position: relative;
}

.product-card-small .product-image-small .tag-images-count {
  position: absolute;
  bottom: 5px;
  left: 5px;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.product-card-small .product-image-small .tag-images-count span {
  margin-left: 5px;
}

.product-card-small .product-image-small .small-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

.product-image-top {
  margin-top: -60px;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  height: 330px;
  width: 100%;
  display: block;
  position: relative;
}
.product-image-top:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 200px;
  width: 100%;
  /* background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ff97b5 100%); */
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  /* background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ff97b5 100%); */
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ff97b5 100%); */
  z-index: 1;
}

.product-image-top .tag-images-count {
  position: absolute;
  bottom: 50px;
  left: 30px;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}

.product-image-top .tag-images-count span {
  margin-left: 5px;
}

.product-image-top .small-btn-wrap {
  position: absolute;
  bottom: 50px;
  right: 30px;
  z-index: 1;
}
.product-image-top-m {
  /* margin-top: -60px; */
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  /* height: auto; */
  width: 100%;
  display: block;
  position: relative;
}
.product-image-top-m:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 200px;
  width: 100%;
  /* background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ff97b5 100%); */
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  /* background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ff97b5 100%); */
  /* background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ff97b5 100%); */
  z-index: 1;
}
.product-image-top-m .tag-images-count {
  position: absolute;
  bottom: 50px;
  left: 30px;
  z-index: 1;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
}
.product-image-top-m .tag-images-count span {
  margin-left: 5px;
}
.product-image-top-m .small-btn-wrap {
  position: absolute;
  bottom: 50px;
  right: 30px;
  z-index: 1;
}
.product-image-top-m .small-btn-wrap {
  position: absolute;
  bottom: 50px;
  right: 30px;
  z-index: 1;
}

.custom-border-bottom {
  border-bottom: 5px solid #f3f5f7;
}

.custom-border-top {
  border-top: 5px solid #f3f5f7;
}

.menu-overlay .main-menu {
  background: url("../../img/background2.png") rgba(255, 255, 255, 0.95) fixed;
  background-size: cover;
  padding: 60px 30px 50px 30px;
  height: 100%;
  width: 80%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  /* display: none; */
  transform: translateX(-100%);
  transition: transform 1000ms;
}

.menu-overlay .main-menu .menu-container {
  background: #ffffff;
  max-width: 300px;
  border-radius: 15px;
  padding: 40px 0;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.menu-overlay .main-menu .menu-container > .icon {
  margin: 0 auto;
  margin-top: -90px;
  border: 2px solid #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}

.menu-overlay .main-menu .menu-container .nav-pills {
  margin-top: 20px;
}

.menu-overlay .main-menu .menu-container .nav-pills .nav-item .nav-link {
  border-radius: 15px;
  line-height: 24px;
  padding: 10px 15px;
  font-size: 16px;
  text-align: left;
  position: relative;
  color: #6c757d;
}

.menu-overlay .main-menu .menu-container .nav-pills .nav-item .nav-link .arrow {
  position: absolute;
  top: 12px;
  bottom: 0;
  right: 10px;
  stroke: #6c757d;
}

.menu-overlay .main-menu .menu-container .nav-pills .nav-item .nav-link.active {
  background: #2f2d51;
  border-bottom: 5px solid #80d4ff;
  color: #ffffff;
}

.menu-overlay
  .main-menu
  .menu-container
  .nav-pills
  .nav-item
  .nav-link.active
  .arrow {
  stroke: #ffffff;
}

.menu-overlay .main-menu .menu-container > .close {
  margin: 0 auto;
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  opacity: 1;
}

.menu-push-content .main-menu {
  background: #ffffff;
  background-size: cover;
  padding: 60px 0px 50px 0px;
  height: 100%;
  width: 300px;
  margin-left: -320px;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: block;
}

.menu-push-content .main-menu .menu-container {
  background: #ffffff;
  max-width: 300px;
  border-radius: 15px;
  padding: 40px;
  margin: 0 auto;
  text-align: center;
  position: relative;
}

.menu-push-content .main-menu .menu-container > .icon {
  margin: 0 auto;
  margin-top: -90px;
  border: 2px solid #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.05);
}

.menu-push-content .main-menu .menu-container .nav-pills {
  margin-top: 20px;
}

.menu-push-content .main-menu .menu-container .nav-pills .nav-item .nav-link {
  border-radius: 15px;
  line-height: 24px;
  padding: 10px 15px;
  font-size: 16px;
  text-align: left;
  position: relative;
  color: #2f2d51;
}

.menu-push-content
  .main-menu
  .menu-container
  .nav-pills
  .nav-item
  .nav-link
  .arrow {
  position: absolute;
  top: 12px;
  bottom: 0;
  right: 10px;
  stroke: rgba(153, 153, 153, 0.3);
}

.menu-push-content
  .main-menu
  .menu-container
  .nav-pills
  .nav-item
  .nav-link.active {
  background: #2f2d51;
  border-bottom: 5px solid #80d4ff;
  color: #ffffff;
}

.menu-push-content
  .main-menu
  .menu-container
  .nav-pills
  .nav-item
  .nav-link.active
  .arrow {
  stroke: #ffffff;
}

.menu-push-content .main-menu .menu-container > .close {
  margin: 0 auto;
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  opacity: 1;
}

.menu-active {
  background: #ffffff;
  background-image: none;
}

.menu-active .main-menu {
  margin-left: 0;
}

.menu-active main {
  border-radius: 15px;
  transform: scale(0.9);
  margin-left: 300px;
  height: 100%;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 1px 15px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border: 1px solid #f4f4f4;
}

.menu-active .footer,
.menu-active .colorsettings,
.menu-active .sidebar-right {
  display: none;
}

.menu-open {
  overflow: hidden;
  width: 100%;
}

.btn.colorsettings {
  position: fixed;
  bottom: 15px;
  top: auto;
  right: 15px;
  border-radius: 30px;
  padding: 0px;
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 40px;
  z-index: 99;
}

.btn.colorsettings span {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
}

.btn.colorsettings.active {
  right: 95px;
}

/* sidebar */
.sidebar-right {
  width: 65px;
  position: fixed;
  right: -85px;
  top: 15px;
  bottom: 15px;
  border-radius: 15px;
  padding: 15px 0px;
  overflow-y: auto;
  z-index: 9999;
  background-color: #ffffff;
  color: #2f2d51;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0px 10px rgba(0, 0, 0, 0.15);
  text-align: center;
}

.sidebar-right.active {
  right: 15px;
}

/* Color select */
.colorselect,
.colorselect2 {
  margin: 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border-radius: 24px;
  overflow: hidden;
  border: 4px solid transparent;
}

.colorselect input[type="radio"],
.colorselect2 input[type="radio"] {
  display: none;
}

.colorselect input[type="radio"] + label,
.colorselect2 input[type="radio"] + label {
  display: block;
  height: 34px;
  line-height: 34px;
  width: 34px;
  color: #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 4px;
  border: 4px solid rgba(255, 255, 255, 0);
}

.colorselect input[type="radio"] + label i,
.colorselect2 input[type="radio"] + label i {
  font-size: 16px;
  display: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  background-color: #80d4ff;
  vertical-align: middle;
}

.colorselect input[type="radio"]:checked + label,
.colorselect2 input[type="radio"]:checked + label {
  border: 4px solid rgba(0, 0, 0, 0.5);
  border-radius: 30px;
}

.colorselect input[type="radio"]:checked + label i,
.colorselect2 input[type="radio"]:checked + label i {
  display: inline-block;
}

.colorselect.active,
.colorselect2.active {
  position: relative;
  border: 4px solid rgba(0, 0, 0, 0.1);
}

.selectoption {
  margin: 0 0;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  border: 4px solid rgba(0, 0, 0, 0.1);
  font-size: 10px;
  border-radius: 24px;
  text-align: center;
  background-color: #ffffff;
}

.selectoption input[type="checkbox"] {
  display: none;
}

.selectoption input[type="checkbox"] + label {
  display: block;
  height: 34px;
  line-height: 28px;
  width: 34px;
  color: #666666;
  margin-bottom: 0;
  cursor: pointer;
  border-radius: 18px;
  border: 4px solid rgba(255, 255, 255, 0);
}

.selectoption input[type="checkbox"]:checked + label {
  color: #ffffff;
  background-color: #000000;
  border: 4px solid rgba(255, 255, 255, 0.3);
}

.selectoption input[type="checkbox"]:checked + label i {
  display: inline-block;
}

.selectoption.active {
  position: relative;
  border: 4px solid rgba(0, 0, 0, 0.1);
}

/* colors */
.bg-dark-blue {
  background-color: #122a73;
}

.bg-dark-purple {
  background-color: #491879;
}

.bg-maroon {
  background-color: #ad162b;
}

.bg-dark-gray {
  background-color: #475861;
}

.bg-dark-pink {
  background-color: #e92063;
}

.bg-dark-brown {
  background-color: #795448;
}

.bg-dark-green {
  background-color: #00b491;
}

.bg-red {
  background-color: #f94620;
}

.bg-pink {
  background-color: #e91e63;
}

.bg-purple {
  background-color: #6121d2;
}

.bg-blue {
  background-color: #143be0;
}

.bg-teal {
  background-color: #00bcd4;
}

.bg-skyblue {
  background-color: #0faff7;
}

.bg-amber {
  background-color: #ffc107;
}

.bg-gray {
  background-color: #607d8b;
}

.bg-slate {
  background-color: #22262b;
  color: #ffffff;
}

.chart-area {
  height: 150px;
  margin-top: 50px;
}

.lists {
  padding-left: 15px;
}

.lists li {
  line-height: 26px;
}

.offers {
  width: auto;
  position: absolute;
  left: -10px;
  top: 20px;
  background: #80d4ff;
  color: #ffffff;
  text-align: left;
  padding: 5px 15px;
  z-index: 3;
  border-radius: 0 5px 5px 0px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
}

.offers:before {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  left: 0px;
  top: -10px;
  border-right: 10px solid #000;
  border-top: 10px solid transparent;
}

.offers:after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  left: 0px;
  bottom: -10px;
  border-right: 10px solid #000;
  border-bottom: 10px solid transparent;
}

.upcoming {
  width: auto;
  position: absolute;
  left: -5px;
  top: 100px;
  background: #80d4ff;
  color: #ffffff;
  text-align: left;
  padding: 3px 10px;
  z-index: 3;
  border-radius: 3px 3px 3px 0px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.upcoming p {
  font-size: 12px;
}

.upcoming:after {
  content: "";
  height: 5px;
  width: 5px;
  position: absolute;
  left: 0px;
  bottom: -5px;
  border-right: 5px solid #000;
  border-bottom: 5px solid transparent;
}

.avatar {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  position: relative;
  border-radius: 4px;
  border-radius: 50%;
  z-index: 0;
  background: rgba(47, 45, 81, 0.15);
}

.avatar img {
  width: 100%;
}

.avatar-20 {
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
}

.avatar-26 {
  width: 26px;
  min-width: 26px;
  height: 26px;
  line-height: 26px;
  font-size: 20px;
}

.avatar-30 {
  width: 30px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
}

.avatar-36 {
  width: 36px;
  min-width: 36px;
  height: 36px;
  line-height: 32px;
  font-size: 15px;
}

.avatar-40 {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
}

.avatar-50 {
  width: 50px;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
}

.avatar-60 {
  width: 60px;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
}

.avatar-70 {
  width: 70px;
  min-width: 70px;
  height: 70px;
  line-height: 70px;
}

.avatar-80 {
  width: 80px;
  min-width: 80px;
  height: 80px;
  line-height: 80px;
}

.avatar-90 {
  width: 90px;
  height: 90px;
  line-height: 90px;
}

.avatar-100 {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.avatar-110 {
  width: 110px;
  height: 110px;
  line-height: 110px;
}

.avatar-120 {
  width: 120px;
  height: 120px;
  line-height: 120px;
}

.avatar-130 {
  width: 130px;
  height: 130px;
  line-height: 130px;
}

.avatar-140 {
  width: 140px;
  height: 140px;
  line-height: 140px;
}

.avatar-150 {
  width: 150px;
  height: 150px;
  line-height: 150px;
}

.avatar-160 {
  width: 160px;
  height: 160px;
  line-height: 160px;
}

.avatar-170 {
  width: 170px;
  height: 170px;
  line-height: 170px;
}

.avatar-180 {
  width: 180px;
  height: 180px;
  line-height: 180px;
}

.avatar-190 {
  width: 190px;
  height: 190px;
  line-height: 190px;
}

.avatar-200 {
  width: 200px;
  height: 200px;
  line-height: 200px;
}

.btn {
  border: 0;
  border-radius: 99px !important;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  -webkit-outline: none;
  font-size: 14px;
  line-height: 31px;
  padding: 6px 20px;
}

.btn.btn-sm {
  font-size: 12px;
  padding: 3px 15px;
}

.btn i,
.btn .material-icons {
  vertical-align: middle;
}

.btn.btn-link-default {
  color: #2f2d51;
}

.btn.menu-btn,
.btn.sqaure-btn {
  height: 44px;
  line-height: 40px;
  vertical-align: middle;
  width: 44px;
  text-align: center;
  padding: 0;
}

.btn.small-btn {
  height: 34px;
  line-height: 30px;
  vertical-align: middle;
  width: 34px;
  text-align: center;
  padding: 0;
}

.btn.btn-info {
  background-color: #80d4ff !important;
  box-shadow: 0 3px 10px rgba(128, 212, 255, 0.5) !important;
  -webkit-box-shadow: 0 3px 10px rgba(128, 212, 255, 0.5) !important;
  -moz-box-shadow: 0 3px 10px rgba(128, 212, 255, 0.5) !important;
  -ms-box-shadow: 0 3px 10px rgba(128, 212, 255, 0.5) !important;
  border-color: #80d4ff !important;
}

.btn.btn-info:hover,
.btn.btn-info:focus {
  background-color: #4dc3ff !important;
  box-shadow: 0 3px 10px rgba(128, 212, 255, 0.75) !important;
  -webkit-box-shadow: 0 3px 10px rgba(128, 212, 255, 0.75) !important;
  -moz-box-shadow: 0 3px 10px rgba(128, 212, 255, 0.75) !important;
  -ms-box-shadow: 0 3px 10px rgba(128, 212, 255, 0.75) !important;
}

.btn.btn-info:focus:active,
.btn.btn-info:active,
.btn.btn-info:not(:disabled).active {
  background-color: #34baff !important;
}

.btn.btn-danger {
  background-color: #ff97b5 !important;
  box-shadow: 0 3px 10px rgba(255, 151, 181, 0.5) !important;
  -webkit-box-shadow: 0 3px 10px rgba(255, 151, 181, 0.5) !important;
  -moz-box-shadow: 0 3px 10px rgba(255, 151, 181, 0.5) !important;
  -ms-box-shadow: 0 3px 10px rgba(255, 151, 181, 0.5) !important;
  border-color: #ff97b5 !important;
}

.btn.btn-danger:hover,
.btn.btn-danger:focus {
  background-color: #ff7ea3 !important;
  box-shadow: 0 3px 10px rgba(255, 151, 181, 0.75) !important;
  -webkit-box-shadow: 0 3px 10px rgba(255, 151, 181, 0.75) !important;
  -moz-box-shadow: 0 3px 10px rgba(255, 151, 181, 0.75) !important;
  -ms-box-shadow: 0 3px 10px rgba(255, 151, 181, 0.75) !important;
}

.btn.btn-danger:focus:active,
.btn.btn-danger:active {
  background-color: #ff6491 !important;
}

.btn.btn-primary {
  background-color: #63abff;
  box-shadow: 0 3px 10px rgba(99, 171, 255, 0.5);
  -webkit-box-shadow: 0 3px 10px rgba(99, 171, 255, 0.5);
  -moz-box-shadow: 0 3px 10px rgba(99, 171, 255, 0.5);
  -ms-box-shadow: 0 3px 10px rgba(99, 171, 255, 0.5);
}

.btn.btn-primary:hover,
.btn.btn-primary:focus {
  background-color: #4a9dff;
  box-shadow: 0 3px 10px rgba(99, 171, 255, 0.75);
  -webkit-box-shadow: 0 3px 10px rgba(99, 171, 255, 0.75);
  -moz-box-shadow: 0 3px 10px rgba(99, 171, 255, 0.75);
  -ms-box-shadow: 0 3px 10px rgba(99, 171, 255, 0.75);
}

.btn.btn-primary:focus:active,
.btn.btn-primary:active {
  background-color: #3090ff;
}

.btn.btn-secondary {
  background-color: #999999;
  box-shadow: 0 3px 10px rgba(153, 153, 153, 0.5);
  -webkit-box-shadow: 0 3px 10px rgba(153, 153, 153, 0.5);
  -moz-box-shadow: 0 3px 10px rgba(153, 153, 153, 0.5);
  -ms-box-shadow: 0 3px 10px rgba(153, 153, 153, 0.5);
}

.btn.btn-secondary:hover,
.btn.btn-secondary:focus {
  background-color: #8c8c8c;
  box-shadow: 0 3px 10px rgba(153, 153, 153, 0.75);
  -webkit-box-shadow: 0 3px 10px rgba(153, 153, 153, 0.75);
  -moz-box-shadow: 0 3px 10px rgba(153, 153, 153, 0.75);
  -ms-box-shadow: 0 3px 10px rgba(153, 153, 153, 0.75);
}

.btn.btn-secondary:focus:active,
.btn.btn-secondary:active {
  background-color: #ff6491;
}

.btn.btn-success {
  background-color: #6be3a2;
  box-shadow: 0 3px 10px rgba(107, 227, 162, 0.5);
  -webkit-box-shadow: 0 3px 10px rgba(107, 227, 162, 0.5);
  -moz-box-shadow: 0 3px 10px rgba(107, 227, 162, 0.5);
  -ms-box-shadow: 0 3px 10px rgba(107, 227, 162, 0.5);
}

.btn.btn-success:hover,
.btn.btn-success:focus {
  background-color: #56df95;
  box-shadow: 0 3px 10px rgba(107, 227, 162, 0.75);
  -webkit-box-shadow: 0 3px 10px rgba(107, 227, 162, 0.75);
  -moz-box-shadow: 0 3px 10px rgba(107, 227, 162, 0.75);
  -ms-box-shadow: 0 3px 10px rgba(107, 227, 162, 0.75);
}

.btn.btn-success:focus:active,
.btn.btn-success:active {
  background-color: #40db87;
}

.btn.btn-warning {
  background-color: #ffdd6d;
  box-shadow: 0 3px 10px rgba(255, 221, 109, 0.5);
  -webkit-box-shadow: 0 3px 10px rgba(255, 221, 109, 0.5);
  -moz-box-shadow: 0 3px 10px rgba(255, 221, 109, 0.5);
  -ms-box-shadow: 0 3px 10px rgba(255, 221, 109, 0.5);
}

.btn.btn-warning:hover,
.btn.btn-warning:focus {
  background-color: #ffd754;
  box-shadow: 0 3px 10px rgba(255, 221, 109, 0.75);
  -webkit-box-shadow: 0 3px 10px rgba(255, 221, 109, 0.75);
  -moz-box-shadow: 0 3px 10px rgba(255, 221, 109, 0.75);
  -ms-box-shadow: 0 3px 10px rgba(255, 221, 109, 0.75);
}

.btn.btn-warning:focus:active,
.btn.btn-warning:active {
  background-color: #ffd13a;
}

.btn.btn-light {
  background-color: #f7f7f7;
  box-shadow: 0 3px 10px rgba(247, 247, 247, 0.5);
  -webkit-box-shadow: 0 3px 10px rgba(247, 247, 247, 0.5);
  -moz-box-shadow: 0 3px 10px rgba(247, 247, 247, 0.5);
  -ms-box-shadow: 0 3px 10px rgba(247, 247, 247, 0.5);
}

.btn.btn-light:hover,
.btn.btn-light:focus {
  background-color: #eaeaea;
  box-shadow: 0 3px 10px rgba(247, 247, 247, 0.75);
  -webkit-box-shadow: 0 3px 10px rgba(247, 247, 247, 0.75);
  -moz-box-shadow: 0 3px 10px rgba(247, 247, 247, 0.75);
  -ms-box-shadow: 0 3px 10px rgba(247, 247, 247, 0.75);
}

.btn.btn-light:focus:active,
.btn.btn-light:active {
  background-color: #dedede;
}

.btn.btn-dark {
  background-color: #2f2d51;
  box-shadow: 0 3px 10px rgba(47, 45, 81, 0.5);
  -webkit-box-shadow: 0 3px 10px rgba(47, 45, 81, 0.5);
  -moz-box-shadow: 0 3px 10px rgba(47, 45, 81, 0.5);
  -ms-box-shadow: 0 3px 10px rgba(47, 45, 81, 0.5);
}

.btn.btn-dark:hover,
.btn.btn-dark:focus {
  background-color: #252441;
  box-shadow: 0 3px 10px rgba(47, 45, 81, 0.75);
  -webkit-box-shadow: 0 3px 10px rgba(47, 45, 81, 0.75);
  -moz-box-shadow: 0 3px 10px rgba(47, 45, 81, 0.75);
  -ms-box-shadow: 0 3px 10px rgba(47, 45, 81, 0.75);
}

.btn.btn-dark:focus:active,
.btn.btn-dark:active {
  background-color: #1c1b30;
}

.btn.btn-lg {
  padding: 4px 20px;
  line-height: 20px;
  font-size: 12px;
}

/* responsive */
@media screen and (min-width: 992px) {
  main,
  body {
    /* background: url("../../img/background-tablet.png") #f3f7fa fixed; */
    background-size: cover;
  }
}

@media screen and (max-width: 370px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .swiper-products .swiper-slide:first-child {
    padding-left: 15px;
  }
  .swiper-products .swiper-slide:last-child {
    padding-right: 15px;
  }
  .product-card-large {
    width: 290px;
    height: auto;
  }
  .mt-4,
  .my-4 {
    margin-top: 1rem !important;
  }
  .mb-4,
  .my-4 {
    margin-bottom: 1rem !important;
  }
  .image-circle > figure {
    height: 200px;
    width: 200px;
  }
  .image-circle {
    padding: 0px 15px 20px 15px;
  }
  .image-circle > .icon {
    margin: 0 auto -60px auto;
  }
}

/* mehrose */
.car {
  background-color: white;
}
.chatList:last-child {
  margin-bottom: 4rem;
}
.incoming {
  background-color: #ff97b52e !important;
}
.outgoing {
  background-color: #80d4ff30 !important;
}
.notifi:last-child {
  margin-bottom: 3.5rem;
}
.modalSpan {
  font-size: 16px;
  font-weight: 500;
}
.mt {
  margin-top: 85px;
}
.mt-6 {
  margin-top: 4rem;
}
.butnList {
  list-style: none;
}
.listStyleNone {
  list-style: none;
}
.active-m {
  transform: translate3d(0%, 0, 0) !important;
  transition: all 300ms ease;
  transition-delay: 70ms;
}
.background-m {
  background-size: contain;
}
.checkbox-lable {
  cursor: pointer;
  background: none;
  display: block;
  width: 100%;
  text-align: center;
  border-radius: 15px;
  color: #999999;
  margin-bottom: 0;
}
.checkbox-lable span {
  display: block;
}
.image-boxed {
  height: 100px;
  line-height: 70px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  display: block;
  /* background: #2F2D51; */
  margin: auto;
}
/* frame */
.frame {
  width: 100%;
  height: 300px;
}
/* newchic */
.bottom-navigation-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  /* min-height: 60px; */
  display: flex;
  z-index: 9999;
  background: #fff;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.center-center {
  align-items: center;
}
.bottom-navigation-wrap:before {
  border-top: 1px solid #bfbfbf;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  box-sizing: border-box;
  font-size: 2.66667rem;
}
.bottom-navigation-wrap .bottom-navigation {
  position: relative;
  padding-top: 12px;
  flex: 1;
  color: #6c757d;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
}
.bottom-navigation-wrap .bottom-navigation.active {
  color: #ff165e;
}
.bottom-navigation-wrap .bottom-navigation img {
  width: 0.64rem;
}
.bottom-navigation-wrap .bottom-navigation p {
  /* font-size: .29333rem; */
  text-transform: capitalize;
  font-size: 12px;
}
.side-new-user {
  height: 3.68rem;
  background-size: 100% 100%;
}
.display-flex {
  display: flex;
}
.nc-align-center {
  display: flex;
  align-items: center;
}
.flex1 {
  flex: 1;
}
.height100 {
  height: 100%;
}
.side-new-user__left {
  color: #fff;
  font-size: 0.32rem;
}
.ps-4 {
  padding-left: 0.42667rem !important;
}
.side-new-user__left b {
  font-size: 0.48rem;
}
.mt-1 {
  margin-top: 0.10667rem !important;
}
.nc-line-height-1 {
  line-height: 1;
}
.side-new-user__right {
  display: flex;
  width: 3.66667rem;
}
.side-new-user__right-button {
  height: 0.66667rem;
  padding: 0 0.29333rem;
  line-height: 0.66667rem;
  border-radius: 0.34667rem;
  max-width: 100%;
  background: #fff;
  color: #f34c5e;
  font-size: 0.32rem;
}
.listings:last-child {
  margin-bottom: 4.5rem;
}
.css-26l3qy-menu {
  z-index: 999 !important;
}
.product-image {
  border-radius: 15px;
  overflow: hidden;
  width: 100%;
  display: block;
  position: relative;
}
.carousalImg {
  height: 235px;
}

.searchBar {
  position: fixed;
  top: 59px;
  z-index: 9;
  background: white;
  padding: 5px;
}
/* .listing-m {
  position: relative;
  top: 51px;
} */
.listing-m:last-child {
  margin-bottom: 4.5rem !important;
}

.toogle {
  height: 30px;
  width: 56px;
  border-radius: 50px;
  border: 1px solid #cfd5db;
  /* background-image: linear-gradient(); */
  background-color: #bedfff;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
}
.toogle .map {
  background-color: #90aac4;
}
.notch {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 1px;
  left: 2px;
  box-shadow: 0 0 5px #bedfff;
  z-index: 1;
  transition: all 0.3s ease;
}
.map > .notch {
  background-color: #fff;
  transform: translate(25px, 0);
}

/* counter */
.counter-head {
  display: flex;
  justify-content: space-between;
}
.counter-body {
  display: flex;
}
.counter-btn {
  height: 24px;
  width: 24px;
  padding: 0px !important;
  display: inline-block;
  border: 1px solid #2e4453;
  border-radius: 50%;
  color: #2e4453;
  background: transparent;
}
.count {
  border: 0;
  width: 25px;
  box-shadow: inset 0 0 0 rgba(64, 150, 255);
  background: transparent;
  margin: 0 12px;
  pointer-events: none;
  text-align: center;
}

/* img check box */

.get_started_radio {
  /* width: 100%;
  float: left; */
  width: 100%;
  float: left;
  /* height: 400px; */
  overflow-y: auto;
  transition: all 0.2s !important;
}
.container_ul_m {
  width: 100%;
  float: left;
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
}
.container_ul_m li {
  color: #c9c9c9;
  display: block;
  position: relative;
  float: left;
  width: 24%;
  margin-right: 1%;
  margin-bottom: 5px;
  height: 90px;
}
.container_ul {
  width: 100%;
  float: left;
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
}
.container_ul li {
  color: #c9c9c9;
  display: block;
  position: relative;
  float: left;
  width: 19%;
  margin-right: 1%;
  margin-bottom: 5px;
  /* height: 90px; */
}
.roomate_form .room_type ul [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.get_started_radio ul li input[type="checkbox"],
.get_started_radio ul li input[type="radio"] {
  position: absolute;
  transition: all 0.2s;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
}
.get_started_radio input[type="checkbox"]:checked ~ .check,
.get_started_radio input[type="radio"]:checked ~ .check {
  color: var(--black);
  opacity: 1;
  background: var(--lightBlue);
  padding: 10px;
  border-color: var(--lightBlue);
  /* border-radius: 30%; */
}
.minimumStay_ul input[type="checkbox"]:checked ~ .check,
.minimumStay_ul input[type="radio"]:checked ~ .check {
  color: var(--black);
  opacity: 1;
  background: var(--lightBlue);
  border-color: var(--lightBlue);
  padding: 10px 17px;
  /* border-radius: 30%; */
}
.check {
  text-align: center;
}
.get_started_radio ul li .check img {
  margin: auto;
  transition: all 0.2s;
  /* padding: 10px; */
  /* width: 30px; */
}
.get_started_radio ul li label {
  display: block;
  position: relative;
  transition: all 0.2s;
  font-family: futuraBkBT;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 145.9%;
  margin: 0;
  height: 100%;
  /* z-index: 9; */
  cursor: pointer;
  text-align: center;
  color: var(--gray);
}
.amenities_check {
  width: 100%;
  float: left;
  margin-top: 15px;
}

/* Date picker */
.calender {
  display: flex;
  flex-direction: column;
}
.calender > div {
  margin-bottom: 1.2rem;
}
.sp {
  margin-left: 35px;
}

/* upload file */
.uploadFileBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 120px;
  background: #fff;
  border: 1px solid #4492da;
  color: #4492da;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Roboto";
  font-size: 12px;
}
.uploadFileBtn:hover {
  border: 1px solid #4492da;
  color: #fff;
  background: #4492da;
}

/* upload preview img */
#profile[type="file"] {
  display: none;
}
#file[type="file"] {
  display: none;
}
#building[type="file"] {
  display: none;
}
.label-holder {
  width: 100%;
  height: 50px;
  margin-top: 3em;
  display: grid;
  place-items: center;
}
.label {
  height: 50px;
  width: 200px;
  background: #fff;
  border: 1px solid #4492da;
  color: #4492da;
  display: grid;
  place-items: center;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Roboto";
}
.abc {
  width: 200px;
  height: 100px;
  margin: 5px 0px;
  position: relative;
}
.cross {
  background-color: #80d4ff;
  position: absolute;
  bottom: 0;
  right: 11px;
  border-radius: 35%;
}
.prevImg {
  width: 100%;
  height: 100px;
  border-radius: 9px;
}

/* .flatpickr-input, .flatpickr-mobile {
  width: 100%;
} */

/*  */
.adults {
  display: flex;
  justify-content: space-between;
}

/* Bootom sheet */
.bootom {
  background: url("../../img/background2.png") rgba(255, 255, 255, 0.95) fixed;
  background-size: cover;
  padding: 60px 30px 50px 30px;
  height: 100%;
  width: 100%;
  height: auto;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  /* display: none; */
  transform: translateY(305%);
  transition: transform 1000ms;
}
.bootom-active {
  border-radius: 30px 30px 0 0;
  transform: translateY(205%);
}

.m2 {
  border-radius: 9px;
  padding: 3px;
}
.listing-address-span-m {
  text-transform: capitalize;
}
.listing-address-span-Cm {
  text-transform: uppercase;
}
/* table */
.pivoted {
  color: #6c757d !important;
  font-size: 80% !important;
}
.tdBefore {
  font-weight: 400 !important;
}
/*  */
.bp-badge--overview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 4px 4px 0;
}
.bui-badge {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  background-color: #333;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-weight: 500;
  padding: 1px 4px;
  vertical-align: middle;
}
.bui-badge--outline {
  border-color: #333;
  background: #fff;
  color: #333;
}
.bui-spacer--small {
  margin-bottom: 4px !important;
}

.bp-review-score__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.bp-review-score-copy__title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
.bp-review-score-copy__subtitle {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.bui-review-score--larg .bui-review-score__badge {
  width: 28px;
  min-width: 28px;
  height: 28px;
  font-size: 14px;
}
.bui-review-score--small .bui-review-score__badge {
  width: 22px;
  min-width: 22px;
  height: 20px;
  font-size: 11px;
}
.bui-review-score__badge {
  background: #80d4ff;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  vertical-align: baseline;
  border-radius: 6px 6px 6px 0;
  height: 32px;
  width: 32px;
  min-width: 32px;
}

/* chatBot */
.chatbox-wrapper {
  position: fixed;
  width: 300px;
  left: calc(50% - 40%);
  bottom: 90px;
  z-index: 999;
}
.chat-popup {
  border: 1px solid transparent;
  border-radius: 50%;
  bottom: 30px;
  color: #fff;
  cursor: pointer;
  position: fixed;
  right: 5.5%;
  z-index: 999;
  width: 55px;
  height: 55px;
  background: #bad4f7;
  text-align: center;
}
.chat-bounce {
  -moz-animation: float 1500ms infinite ease-in-out;
  -webkit-animation: float 1500ms infinite ease-in-out;
  animation: float 1500ms infinite ease-in-out;
}
@keyframes float {
  0% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    transform: translateY(5px);
  }
}
.agent-chat-form {
  background: #fff;
  /* box-shadow: 0 0 15px rgba(51, 51, 51, 0.1); */
  box-shadow: 0 20px 15px rgba(55, 55, 55, 0.15);
  transition: 0.5s;
  padding: 15px 0;
  border-radius: 5px;
  display: none;
}
.agency-chat-img {
  max-width: 100%;
  margin-bottom: 6px;
}

/* filter Popup */
.filter-popup {
  border: 1px solid transparent;
  border-radius: 50%;
  /* bottom: 30px; */
  color: #fff;
  cursor: pointer;
  position: fixed;
  right: 5.5%;
  z-index: 999;
  width: 55px;
  height: 55px;
  background: #80d4ff;
  text-align: center;
}
.filter-bounce {
  -moz-animation: float 1500ms infinite ease-in-out;
  -webkit-animation: float 1500ms infinite ease-in-out;
  animation: float 1500ms infinite ease-in-out;
}
@keyframes float {
  0% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    transform: translateY(5px);
  }
  50% {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  100% {
    -webkit-transform: translateY(5px);
    -moz-transform: translateY(5px);
    transform: translateY(5px);
  }
}
.am-modal-content {
  border-radius: 20px 20px 0 0;
}
.popup-list .am-list-body {
  /* height: 210px; */
  /* overflow: auto; */
}
/* steps */
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

.steps-action {
  margin-top: 24px;
}

/* ant */
/* .slick-list {
  min-height: 255px;
  max-height: 255px;
} */
.am-navbar,
.am-navbar-light {
  height: auto !important;
  align-items: flex-start !important;
  width: 100%;
}
/* .am-popover-arrow {
  right: 27px !important;
} */
.am-navbar-title,
.am-navbar-right {
  /* flex: auto !important; */
  height: auto !important;
  /* display: unset !important; */
  /* align-items: center !important; */
}
.am-navbar-right {
  width: 20%;
}
.am-navbar-title {
  width: 50%;
}
.am-navbar-left {
  width: 30%;
}
.ant-modal-header,
.ant-modal-content {
  border-radius: 20px !important;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0 !important;
}
.anticon {
  vertical-align: 0.125em !important;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  line-height: 4.4285 !important;
}
.ant-badge {
  line-height: unset !important;
}
.ant-scroll-number,
.ant-badge-count {
  top: -17px !important;
  right: 16px !important;
}
.coliving-city > .ant-select-selector {
  border: none !important;
}
/* init-phone */
.react-tel-input .form-control {
  width: auto !important;
}
/* map */
.listingMap {
  width: 200px;
  height: 200px;
  margin: 5px 0px;
  position: relative;
}
.custom-map-control-button {
  appearance: button;
  background-color: #fff;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin: 10px;
  padding: 0 0.5em;
  height: 40px;
  font: 400 18px Roboto, Arial, sans-serif;
  overflow: hidden;
}
.custom-map-control-button:hover {
  background: #ebebeb;
}
/* profile */
.languagesList {
  border: 1px solid #bad4f7;
  display: inline-table;
  padding: 0px 10px !important;
  margin-top: 5px;
  margin-right: 5px;
  cursor: pointer;
  background: #ebf3ff;
  border-radius: 25px;
}
