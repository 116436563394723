.profile {
  padding: 80px 0;
  color: #1c1c1c;
  font-family: futuraBkBt;

  .profile__top {
    display: flex;
    align-items: center;
    padding: 0 20px;

    .MuiAvatar-root {
      width: 70px !important;
      height: 70px !important;
      margin-right: 20px;
    }

    h2 {
      font-family: futuraMdBt;
      color: #1c2c4a;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .profile__body {
    padding: 30px 25px;
    padding-bottom: 15px;

    .profile__body-info {
      padding-bottom: 25px;

      h3 {
        font-family: futuraMdBt;
        font-size: 14px;
        margin-bottom: 7px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;

        span {
          font-family: futuraBkBt;
          font-weight: bold;

          &.info-verified {
            color: #1dac97;
          }

          &.info-unverified {
            color: #e04025;
          }
        }
      }

      .info-nothing {
        font-weight: normal;
        color: rgba(0, 0, 0, 0.35);
      }
    }
  }

  .profile__edit {
    padding: 0 20px;
  }

  .verify__inputs {
    display: flex;
    align-items: center;
    justify-content: center;

    .verify__input {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 60px;
      font-size: 26px;
      margin: 5px !important;
      text-align: center;
      padding: 12px 16px;
      transition: all 0.2s;

      &:focus {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
        border-color: transparent;
      }
    }
  }
}
