.change-pass {
  padding: 65px 0;
  font-family: futuraBkBt;
  color: #1c1c1c;

  &__body {
    padding: 20px 15px;

    label {
      margin-bottom: 0;
    }

    input {
      margin-bottom: 15px;
    }

    button {
      margin-top: 20px;
    }
  }
}
