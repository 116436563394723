html,
body,
#root,
.App,
.chat-container,
.chat {
  height: 100%;
}

.chat {
  font-family: futuraBkBt;
  padding-top: 65px;

  .chat__no-chats {
    padding: 25px 15px;

    &-img {
      text-align: center;
      padding: 30px 0;
      padding-bottom: 40px;

      img {
        width: 250px;
      }
    }

    h3 {
      color: #1c2c4a;
      font-family: futuraMdBt;
      font-size: 16px;
    }

    p {
      color: #5e5e5e;

      a {
        color: #1c2c4a !important;
        text-decoration: underline !important;
      }
    }
  }

  .chat__void {
    padding: 50px !important;
  }
}
