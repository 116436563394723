// .ant-select-dropdown {
//   width: 150px !important;
// }

.co-living {
  height: 100%;
  width: 100%;
  font-family: futuraBkBt, serif;

  .search-listing__actions {
    .ant-select-selector {
      width: 125px !important;
      height: 40px !important;
      padding-top: 3px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 999px !important;
    }
  }

  .co-living__body {
    padding-top: 60px;

    // .search-container {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;

    //   .cities {
    //     width: 100%;

    //     .ant-select {
    //       width: 100%;
    //       border-radius: 999px;
    //       border: 1px solid rgba(0, 0, 0, 0.15);
    //     }
    //   }
    // }
  }

  .loc-info {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    padding-bottom: 7px;

    div {
      display: flex;
      flex: 0.5 !important;

      ul {
        width: 100% !important;
      }

      button {
        width: 100%;
      }
    }

    .lists {
      display: flex;
      flex-wrap: wrap;

      .loc-list {
        margin-right: 5px;
        width: fit-content;
        border: 1px solid #4d71b6;
        display: flex;
        align-items: center;
        padding: 0px 10px !important;
        color: #1c1c1c;
        margin-top: 5px;
        margin-right: 5px;
        cursor: pointer;
        background: rgba(77, 113, 182, 0.15);
        border-radius: 25px;
      }
    }
  }

  .co-living__no-listing {
    padding: 15px 0;
    text-align: center;
  }

  .co-living__listing {
    padding: 15px 0;

    .co-living__list {
      // margin-bottom: 35px;

      .co-living__carousel {
        height: 250px !important;
        // overflow: hidden !important;

        .slider-frame {
          background-color: rgba(204, 230, 237, 0.35);
          height: 250px !important;

          img {
            object-fit: cover;
            // overflow: hidden !important;
            // height: 250px !important;
            // height: 100% !important;
            // width: unset !important;
            width: 100% !important;
          }
        }
      }

      .co-living__ripple {
        display: block !important;
        text-align: left !important;
        width: 100%;

        > div {
          padding-top: 5px;
          padding-bottom: 20px;
        }

        .co-living__list-body {
          padding: 15px;
          width: 100%;

          h2 {
            font-family: futuraMdBt, sans-serif;
            font-size: 20px;
            color: #1c2c4a;
            margin-bottom: 5px;
            width: 333px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          p {
            font-size: 16px;
            color: grey;
            margin-bottom: 10px;
          }

          .co-living__info {
            display: flex;
            align-items: center;

            div {
              padding: 5px 10px;
              border: 1px solid #e8e9ed;
              border-radius: 100px;
              margin-right: 7px;

              img {
                width: 15px;
                margin-right: 5px;
              }

              span {
                color: #5e5e5e;
              }
            }
          }
        }

        .co-living__list-footer {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-top: -3px;
          padding: 0 15px;
          width: 100%;

          .co-living__pricing {
            color: grey;
            flex: 1;

            p {
              margin-bottom: 3px;
            }

            .co-living__price {
              font-family: Montserrat, sans-serif;
              font-size: 16px;
              margin-bottom: 0;

              span {
                font-weight: bold;
                font-size: 18px;
                color: #1c2c4a;
              }
            }
          }

          .co-living__cta {
            padding-bottom: 5px;

            button {
              color: #1c2c4a;
              font-family: futuraMdBt, sans-serif;
              text-transform: inherit;
              background-color: #cce6ed;
              width: 145px;
              border-radius: 200px;
              box-shadow: 2px 2px 8px #cce6ed;

              img {
                margin-right: 7px;
              }
            }
          }
        }
      }
    }
  }
}
