.sidebar-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  backdrop-filter: blur(1px);
  transition: all 0.3s ease-in-out;
}

.sideBar {
  background: white;
  padding: 15px;
  width: 80%;
  height: 100%;
  overflow-y: auto;
  position: fixed;
  top: 55px;
  left: 0;
  z-index: 999999;
  -webkit-transform: translateX(125%);
  transform: translateX(125%);
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.active {
  -webkit-transform: translateX(25%) !important;
  transform: translateX(25%) !important;
  transition: all 0.3s ease;
  transition-delay: 70ms;
}

.empty-space {
  height: 65px;
}
