.contact-us {
  font-family: futuraBkBt;
  color: #1c1c1c;

  .contact-us__body {
    padding: 80px 0;

    .contact-us__form {
      padding: 0 15px;

      h4 {
        font-family: futuraMdBt;
        font-size: 16px;
        color: #1c1c1c;
      }

      input,
      textarea {
        margin-top: 5px;
        margin-bottom: 10px;
        border: double;
        font-size: large;
        font-weight: 600;
      }
    }

    .contact-us__card {
      margin: 0 15px;
      margin-top: 35px;
      padding: 20px 15px;
      box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1);
      border-radius: 12px;

      .contact-us__info {
        padding-bottom: 20px;

        h5 {
          font-size: 16px;
          font-family: futuraMdBt;
        }

        p {
          color: #6f6f6f;
          margin-bottom: 0;

          strong {
            color: #1c2c4a;
          }
        }
      }

      .contact-us__links {
        p {
          margin-bottom: 2px;
          color: #1c2c4a;
          text-decoration: underline;
        }
      }
    }
  }
}
