.verify {
  height: 100%;
  width: 100%;
  font-family: futuraBkBt, sans-serif;

  .verify__dots--top {
    position: absolute;
    top: -10px;
    right: 5px;
    z-index: -999;
  }

  .verify__dots--bottom {
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: -999;
  }

  .verify__body {
    padding-top: 75px;
    text-align: center;

    .verify__image {
      margin-bottom: 15px;
    }

    h1 {
      font-family: Montserrat, sans-serif;
      font-weight: 900;
      font-size: 22px;
      color: #1c2c4a;
      margin-bottom: 0;
      text-align: center;
    }

    .verify__form {
      padding: 0 20px;

      .verify__inputs {
        display: flex;
        align-items: center;
        justify-content: center;

        .verify__input {
          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 15px;
          width: 60px;
          font-size: 26px;
          margin: 5px 10px !important;
          text-align: center;
          padding: 12px 16px;
          transition: all 0.2s;

          &:focus {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
            border-color: transparent;
          }
        }
      }

      .verify__button {
        margin-top: 20px;
        margin-bottom: 15px;
        font-family: futuraMdBt, sans-serif;
        font-size: 16px;
        width: 100%;
        padding: 10px;
        text-transform: inherit !important;
        background-color: #cce6ed;
        color: #1c2c4a;
        box-shadow: 2px 2px 8px #cce6ed;
        border-radius: 999px;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .verify__socials {
      width: 100%;
      padding: 0 20px;
      text-align: center;

      .verify__caption {
        color: grey;
        margin-bottom: 15px;
      }

      .verify__social-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        .verify__social-btn {
          color: #1c1c1c;
          z-index: 1;
          font-family: futuraBkBt, sans-serif;
          font-size: 16px;
          text-transform: inherit !important;
          padding: 10px;
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 999px;
          border: 1px solid #a2a2a2;

          // &:first-child {
          //   margin-right: 8px;
          // }

          // &:last-child {
          //   margin-left: 8px;
          // }

          img {
            margin-right: 10px;
          }
        }

        .verify__hidden {
          display: none !important;
        }
      }
    }

    .verify__signup-caption {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;

      span {
        margin-right: 6px;
        color: gray;
      }

      a {
        color: #4d71b6 !important;
        text-decoration: underline !important;
        margin-left: 6px;
      }
    }
  }
}
