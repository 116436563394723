.p-type {
  display: flex;
  width: 100%;
}

.active-item {
  background: #cce6ed !important;
}

.p-circle {
  display: block;
  min-width: 85px !important;
  z-index: 1;

  .p-circle-60 {
    width: 52.9px;
    height: 52.9px;
    border-radius: 50%;
    z-index: 2;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    background: rgba(255, 255, 255, 0.4);
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  }
}
