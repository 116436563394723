.new-sr__body {
  padding: 75px 0;
  font-family: futuraBkBt;

  .new-sr__img-container {
    text-align: center;
    padding-bottom: 25px;
  }

  .new-sr__checkboxes {
    padding: 0 20px;

    .sr-col-60 {
      display: block;
      flex: 0 0 60%;
      max-width: 60%;
    }

    .sr-col-40 {
      display: block;
      flex: 0 0 40%;
      max-width: 40%;
    }

    .sr-checkbox {
      display: flex;
      align-items: center;
      color: #1c1c1c;
      font-size: 16px;
      padding-bottom: 7px;

      img {
        margin-right: 5px;
      }
    }
  }

  .new-sr__presence {
    padding: 15px 20px;

    > p {
      margin-bottom: 10px;
    }

    span {
      font-size: 18px;
    }

    &-active {
      display: flex;
      align-items: center;

      .new-item {
        flex: 0.5;

        h3 {
          font-family: futuraMdBt;
          font-size: 16px;
          margin-bottom: 10px;
        }

        .ant-picker {
          width: 100%;
        }

        &:first-child {
          padding-right: 10px;
        }
      }
    }
  }

  .new-sr__inputs {
    padding: 0 20px;

    .description {
      padding-top: 10px;
      display: flex;
      flex-direction: column;

      h3 {
        font-family: futuraMdBt;
        font-size: 16px;
        margin-bottom: 10px;
      }

      textarea {
        outline: none;
        padding: 10px;
        border: 1px solid #a2a2a2;
        border-radius: 15px;
        resize: none;
        height: 95px;
        transition: all 0.2s;

        &:focus {
          border-color: black;
        }
      }
    }

    .new-sr__images {
      padding: 5px 0;

      .upload-file-btn {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: futuraBkBt;
        color: #1c1c1c;
        border: 1px solid #a2a2a2;
        border-radius: 12px;
        padding: 12px;
        width: 100%;
        transition: all 0.2s;

        span {
          padding-left: 15px;
        }

        &:active,
        &:focus-within {
          border-color: black;
          color: black;
        }
      }

      .custom-cross {
        color: #1c1c1c !important;
        height: 30px;
        border-radius: 999px;
        width: 30px;
        display: grid;
        place-items: center;
        background: rgba(255, 255, 255, 0.25);
        backdrop-filter: blur(5px);
        top: 20px;
        right: 18px;
      }
    }
  }

  .new-sr__submit {
    padding: 15px 15px;

    button {
      width: 100% !important;
    }
  }
}
