.post-an-ad {
  .side-image img {
    height: 120px !important;
    object-fit: contain !important;
  }

  .post-card-vm {
    height: 122px;
    display: table-cell;
    vertical-align: middle;
  }

  .verify__inputs {
    display: flex;
    align-items: center;
    justify-content: center;

    .verify__input {
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      width: 60px;
      font-size: 26px;
      margin: 5px !important;
      text-align: center;
      padding: 12px 16px;
      transition: all 0.2s;

      &:focus {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
        border-color: transparent;
      }
    }
  }
}
