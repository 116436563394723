.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--black) !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 16px;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: var(--black) !important;
  pointer-events: none;
}

.bookingCard-img {
  height: 100% !important;
  width: 100%;
  object-fit: cover !important;
  border-radius: 12px 0px 0px 12px;
}

.small_icon {
  width: 18px;
  height: 20px;
}

.booking-page .ant-tabs-tab {
  width: 100px !important;
  text-align: center !important;
  display: grid;
  font-family: futuraBkBt;
  place-items: center;
}

.view-btn {
  padding: 3px 20px !important;
}

.custom-tab .ant-tabs-tab,
.custom-tab .ant-tabs-tab-active {
  margin: 0 !important;
}

.custom-subtab {
  font-family: futuraBkBt;
}

.custom-subtab .ant-tabs-nav-list {
  margin-top: -15px;
}

.custom-subtab .ant-tabs-tab,
.custom-subtab .ant-tabs-tab-active {
  /* height: 75px !important; */
  font-size: 14px !important;
}

.custom-subtab .ant-tabs-tab-active {
  font-weight: bold !important;
}

.custom-subtab .ant-tabs-tab {
  margin-left: -6.5px !important;
  // padding-left: 1px !important;
  // padding-right: 1px !important;
}

.custom-subtab .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 14px;
}

.custom-subtab .ant-tabs-nav {
  justify-content: center !important;
}
