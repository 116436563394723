.gm-style-mtc,
.gm-svpc,
.gmnoprint {
  display: none;
}
.ant-select-selector,
.ant-select {
  border: none ;
}
.ant-steps-item-icon {
  background: var(--lightBlue) !important;
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--xyz);
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--xyz) !important;
}
.place-an-ad {
  * {
    font-family: futuraBkBt;
  }

  input,
  textarea {
    font-family: futuraMdBt !important;
    // font-weight: bold !important;
  }

  .map-container-post-ad {
    position: sticky;
    top: 55px;
    left: 0;
    z-index: 999;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }

  .ant-steps {
    padding: 16px 32px;
    flex-direction: row;
  }
  .steps-content {
    margin: 16px;
    border-radius: 24px;
    text-align: left;
    min-height: 0;
    padding-top: 0;
    .ant-card {
      border-radius: 8px;
      .ant-card-body {
        padding: 0;
      }
    }
  }
  .steps-action {
    margin: 16px;
  }
  .ant-btn {
    width: 100%;
  }
  .ant-select-selector,
  .ant-select {
    border: none !important;
  }
  .label-holder {
    margin: 1em 0;
  }
  .get_started_radio {
    width: 100%;
    transition: all 0.2s !important;
  }
  .container_ul_m {
    width: 100%;
    float: left;
    padding: 0;
    margin-top: 5px;
    list-style-type: none;
  }
  .filter-Am {
    background: #fff;
    border: 1px solid #a2a2a2;
    box-sizing: border-box;
    border-radius: 11px;

    img {
      height: 20px;
      width: 20px;
    }
  }
  .container_ul {
    width: 100%;
    float: left;
    padding: 0;
    margin-top: 5px;
    list-style-type: none;

    li {
      color: white;
      display: block;
      position: relative;
      float: left;
      margin-right: 1%;
      margin-bottom: 5px;
    }
  }
  .roomate_form .room_type {
    ul [type="radio"]:not(:checked) {
      position: absolute;
      left: -9999px;
    }
  }
  .get_started_radio ul li input[type="checkbox"],
  .get_started_radio ul li input[type="radio"] {
    position: absolute;
    transition: all 0.2s;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
  }
  .get_started_radio input[type="checkbox"]:checked ~ .check,
  .get_started_radio input[type="radio"]:checked ~ .check {
    color: var(--black);
    opacity: 1;
    background: var(--lightBlue);
    padding: 10px;
    border-color: var(--lightBlue);
  }
  .minimumStay_ul input[type="checkbox"]:checked ~ .check,
  .minimumStay_ul input[type="radio"]:checked ~ .check {
    color: var(--black);
    opacity: 1;
    background: var(--lightBlue);
    border-color: var(--lightBlue);
    padding: 10px 17px;
  }
  .check {
    text-align: center;
  }
  .get_started_radio {
    ul {
      li {
        label {
          display: block;
          position: relative;
          transition: all 0.2s;
          font-family: futuraBkBT;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 145.9%;
          margin: 0;
          height: 100%;
          /* z-index: 9; */
          cursor: pointer;
          text-align: center;
          color: var(--gray);
        }
        .check {
          img {
            margin: auto;
            transition: all 0.2s;
          }
        }
      }
    }
  }
  // .get_started_radio {
  //     ul {
  //         li {

  //         }
  //     }
  // }

  .count {
    padding: 0 10px;
  }
  /* upload file */
  .uploadFileBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 120px;
    background: #fff;
    border: 1px solid #4492da;
    color: #4492da;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Roboto";
    font-size: 12px;
  }
  .uploadFileBtn:hover {
    border: 1px solid #4492da;
    color: #fff;
    background: #4492da;
  }

  /* upload preview img */
  #profile[type="file"] {
    display: none;
  }
  #file[type="file"] {
    display: none;
  }
  #building[type="file"] {
    display: none;
  }
  .label-holder {
    width: 100%;
    height: 50px;
    margin-top: 3em;
    display: grid;
    place-items: center;
    .label {
      height: 50px;
      width: 100%;
      background: #fff;
      border: 1px solid var(--black);
      color: var(--black);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      cursor: pointer;
    }
  }
  .gallery {
    position: relative;
    .prevImg {
      width: 100%;
      height: 100px;
      object-fit: contain;
      border-radius: 9px;
    }
    .cross {
      background-color: #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      border-radius: 12px;
    }
  }
}

.ant-steps-item-active {
  .ant-steps-icon {
    color: #4492da !important;
  }
}
