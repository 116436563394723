.custom_card {
    border-radius: 16.0146px 16.0146px 0px 0px;

    .card_img {
        width: 100%;
        border-radius: 16.0146px 16.0146px 0px 0px;
    }
    .background_colour_primary {
        border-radius: 0 0 16.0146px 16.0146px;
        padding: 10px 15px;
    }
}