.thank-you {
  color: #1c1c1c;
  font-family: futuraBkBt;
  padding: 50px 0;

  &__body {
    padding: 0 15px;
    text-align: center;

    img {
      margin-top: 40px;
      width: 225px;
      object-fit: contain;
    }

    &-content {
      h1 {
        margin-top: 50px;
        font-family: futuraMdBt;
        color: #1c2c4a;
        font-size: 26px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          margin-top: 35px;
          white-space: nowrap;
          font-family: futuraBkBt !important;
          font-weight: bold;

          &:first-child {
            margin-right: 5px;
          }

          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }

    &-home {
      margin-top: 60px;
    }
  }
}
