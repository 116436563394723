.signup {
  height: 100%;
  width: 100%;
  font-family: futuraBkBt, sans-serif;

  .signup__dots--top {
    position: absolute;
    top: -10px;
    right: 5px;
    z-index: -999;
  }

  .signup__dots--bottom {
    position: absolute;
    bottom: -5px;
    left: 0;
    z-index: -999;
  }

  .signup__body {
    padding-top: 75px;

    h1 {
      font-family: Montserrat, sans-serif;
      font-weight: 900;
      font-size: 22px;
      color: #1c2c4a;
      text-align: center;
      margin-bottom: 0;
    }

    .signup__form {
      padding: 5px 18px;

      .signup__label {
        font-size: 16px;
        font-family: futuraMdBt, sans-serif;
        line-height: 20.5px;
        color: #1c1c1c;
        margin-top: 10px;
        margin-bottom: 0;
      }

      .signup__input {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        width: 100%;
        padding: 12px 16px;
        margin: 7px 0;
        transition: all 0.2s;

        &:focus {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
          border-color: transparent;
        }
      }

      .signup__notice {
        padding: 5px 0;
        color: gray;

        a {
          color: #4d71b6 !important;
        }
      }

      .signup__button {
        margin: 10px 0;
        font-family: futuraMdBt, sans-serif;
        font-size: 16px;
        width: 100%;
        padding: 10px;
        text-transform: inherit !important;
        background-color: #cce6ed;
        color: #1c2c4a;
        box-shadow: 2px 2px 8px #cce6ed;
        border-radius: 999px;

        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .signup__login-caption {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 5px;

      span {
        margin-right: 6px;
        color: gray;
      }

      a {
        color: #4d71b6 !important;
        text-decoration: underline !important;
        margin-left: 6px;
      }
    }
  }
}
