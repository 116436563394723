.ant-picker-range {
  border-radius: 11px !important;
  padding: 5px;
}

.ant-picker-panels {
  flex-direction: column;
}

.filter-checkbox-list,
.hotel-feature-list {
  columns: 2;
}

.ant-collapse-borderless {
  background-color: #ffffff !important;
  border: 0;
}

.guest {
  padding: 5px 5px;
  background: white;
  border: 1px solid #dfe0e0;
  border-radius: 11px;
}

.button-counter {
  width: 20px;
  height: 20px;
}

.bottom-navigation {
  position: fixed;
  z-index: 99999;
  bottom: 0px;
  width: 100%;
  padding: 10px;
}

.bottom-navigation-wrap {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: #fff;
  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* padding: 10px; */
}

.bottom-navigation-wrap:before {
  /* border-top: 1px solid #bfbfbf; */
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  box-sizing: border-box;
  font-size: 2.66667rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bootomBtn {
  background: none;
  border: 2px solid var(--lightBlue);
  background-color: var(--lightBlue);
  border-radius: 200px;
  padding: 5px 30px;
  outline: none;
  box-shadow: 2px 2px 8px #cce6ed;
  color: var(--black);
}

.hotel-date-pickers > .ant-picker {
  z-index: 2;
  border-radius: 999px;
  width: 100%;
}

.hotel-date-pickers > .ant-picker.ant-picker-disabled {
  opacity: 0.9;
  background: #fff !important;
  border-color: #d9d9d9 !important;
}

.hotel-date-pickers > .ant-picker-clear {
  display: none;
}

.hotel-date-pickers .ant-picker {
  z-index: 2;
  border-radius: 999px;
}

.hotel-date-pickers .ant-picker.ant-picker-disabled {
  opacity: 0.9;
  background: #fff !important;
  border-color: #d9d9d9 !important;
}

.hotel-date-pickers .ant-picker .ant-picker-clear {
  display: none;
}

.hotel-discount {
  font-family: futuraBkBt;
  font-size: 16px;
}

.discount-table * {
  font-family: futuraBkBt !important;
}

.discount-table thead * {
  font-weight: bold !important;
}
