.notif {
  padding: 80px 0;
  font-family: futuraBkBt;

  .notif__no-messages {
    padding: 0 15px;

    .notif__img-container {
      text-align: center;
      padding: 30px 0;
      padding-bottom: 40px;

      img {
        width: 250px;
      }
    }

    .notif__no-messages-info {
      text-align: left;
      color: #5e5e5e;

      h3 {
        font-family: futuraMdBt;
        font-size: 16px;
        color: #1c2c4a;
      }

      a {
        color: #1c2c4a !important;
        text-decoration: underline !important;
      }
    }
  }

  .notif__messages {
    box-sizing: border-box;
    padding: 0 20px;

    .nn {
      display: flex;
      align-items: start;
      padding: 15px 0;

      .nn__icon {
        padding-right: 15px;
      }

      .nn__info {
        .nn__msg {
          margin-bottom: 5px;
          color: #1c2c4a;
          font-weight: bold;
          width: 100%;
          word-break: break-word;
        }

        .nn__time {
          color: #5e5e5e;
        }
      }
    }
  }
}
