.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 2;
  // background: white;
}

.screen-header {
  background: #f5fafb;
}

.layoutBody {
  .ml-5 {
    margin-left: 5px;
  }

  .side-image {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 12px;
      height: 150px;
    }
  }

  .card-footer {
    background: white !important;

    .selector-ant {
      margin-left: 10px;
    }

    .ant-select-selector {
      border-radius: 12px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
    }
  }
}

.bottom-navigation {
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  background: #fff;
}
