.mobile-nav {
  font-family: futuraBkBt;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border: none !important;
}

.chat-count-badge {
  .ant-scroll-number,
  .ant-badge-count {
    right: 0 !important;
  }
}
