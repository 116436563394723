.rd-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -999;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  transition: all 0.3s ease-in-out;

  &.rd-modal--active {
    opacity: 1;
    z-index: 99999999;
  }

  .rd-modal__container {
    position: fixed;
    flex: 1;
    width: 100%;
    bottom: -500px;
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 25px 25px 0 0;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    &.rd-modal__container--active {
      bottom: 0;
      opacity: 1;
    }
  }
}
