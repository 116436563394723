.blogs {
  color: #1c1c1c;
  font-family: futuraBkBt;
  padding: 75px 0;

  &__body {
    padding: 0 15px;

    .blog-title {
      color: #1c2c4a;
    }
  }
}
