.service-requests {
  font-family: futuraBkBt;
  color: #1c1c1c;
  padding: 65px 0;

  &__card {
    padding: 0 15px;

    h3 {
      font-size: 16px;
      color: #1c2c4a;
      font-family: futuraMdBt;
      margin-bottom: 20px;
    }

    table {
      width: 100%;

      thead,
      tbody {
        tr {
          display: flex;
          align-items: center;

          td {
            flex: 0.23;

            &.last {
              flex: 0.31;
            }
          }
        }
      }

      thead {
        color: #5e5e5e;

        tr {
          padding-bottom: 15px;
        }
      }

      tbody {
        color: #1c2c4a;

        tr {
          padding-bottom: 10px;
        }
      }
    }
  }
}
