.pr-15{
    padding-right: 15px;
} 
.pl-15 {
    padding-left: 15px;
}
.tiles_card {
    width: 175px;
    min-height: 124px;
    background: var(--white);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    padding: 15px 15px;

    .tiles_item {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }
}