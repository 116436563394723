.footer {
    width: 100%;
    height: 58%;

    .footer_bg {
        width: 100%;
        height: 100%;
        background-image: url('../../images/footerBg.png');
        background-size: cover;
        background-repeat: no-repeat;

        display: flex;
        align-items: center;
        justify-content: center;

        .footer_content {
            text-align: center;
            p {
                line-height: 115%;
            }

            .footer_btn {
                display: flex;
                flex-direction: column;
            }
        }

    }
}