.homeBody {
  /* background-image: url("../../images/homeBg.png");
  background-repeat: no-repeat; */
  display: block;
  /* width: 100%;
  height: 100%;
  top: 0; */
  /* padding-top: 55px; */
  /* z-index: 2; */
}
.mobileBanner {
  width: 100%;
  height: 500px;
}
.mobileBannerImage {
  width: 100%;
  height: 100%;
  /* background-image: url('../../images/homePage.png'); */
   background-image: url('../../images/home-min.png');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
}
/* .mobileBannerText { */
  /* position: absolute;
  top: calc(50% - 33%);
  right: calc(56% - 47%); */
/* } */
.propertyTypes {
  position: absolute;
  bottom: 0;
  background-color: transparent;
}
.propertyTypesDot {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
.space-carousel {
  padding: 16px 0px;
  background: white;
  overflow: hidden;
}
/* .greenBackground {
  position: relative;
  width: 100%;
  height: auto;
  background: #d0f3da;
  border-radius: 12px;
} */
/* .redBackground {
  position: relative;
  width: 100%;
  height: auto;
  background: #ffdfda;
  border-radius: 12px;
} */
.blueBackground {
  position: relative;
  width: 100%;
  min-height: 70px;
  background: #cce6ed;
  border-radius: 12px;
}
.b {
  position: absolute;
  top: 0;
  left: 0;
}
.roundDots {
  float: right;
}
.cardItemUl {
  list-style: none;
  padding: 0;
}
.cardItemsList {
  width: 60px;
  background: white;
  border: 1px solid gray;
  border-radius: 15px;
  padding: 2px 2px 5px 9px;
  /* vertical-align: middle;
    text-align: center;
    display: table-cell; */
  margin: 4px;
}
.comunity {
  overflow: hidden;
  display: flex;
  align-items: center;
}
.comunityImg {
  width: 37%;
  max-height: 75px;
  object-fit: cover;
  text-align: center
}
.comunity-text {
  /* width: 70%; */
  display: table-cell;
  margin: auto;
  /* padding-top: 25px; */
}
.carousalImg {
  width: 100%;
  height: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 12px 12px 0 0;
}
.custom.container {
  padding: 0 8px !important;
}
button.book-btn {
  padding: 5px 25px !important;
  color: #1c2c4a !important;
}
.card__title {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogs-section .space-carousel {
  background: none !important;
}
.home_story_card {
  position: relative;
  margin-top: 136px;
}
.homePage_acordion {
  width: 95%;
  margin: auto;
  padding: 20px !important;
  display: flex;
  align-items: center;
  position: absolute;
  top: -143px;
  left: 0;
  right: 0;
}
.card-flex {
  display: flex;
  flex-wrap: wrap;
  /* align-items: center; */
}
.card-flex-item {
  flex: 1;
  text-align: center;
  padding: 5px;
}
.card-flex-item-img {
  /* width: 100%; */
  height: 101px;
}
.customTabs {
  width: 344px;
  height: 40px;
  border: 1px solid var(--lightBlue);
  box-sizing: border-box;
  border-radius: 30px;
}
.customTabsBtn {
  width: 114px;
  height: 40px;
  background: none;
  border: none;
}
.customOpenTab, .customOpenTabBooking {
  background: #CCE6ED;
  border-radius: 30px;
}
.emptyCard {
  width: 300px;
  height: 345px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: var(--lightGray) !important;
  box-shadow: 0px 8.5061px 34.0244px rgba(0, 0, 0, 0.12);
  border-radius: 18.7134px;
}

.seracc {
  height: 40px;
}

/* animation */
.animated-text {
  height: 30px;
  overflow: hidden;
}
.line {
  font-family: futuraMdBt;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: var(--black);
  line-height: 30px;
}
.line:first-child {
  animation: text-animate 15s infinite;
}
@keyframes text-animate {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: -30px;  
  }
  20% {
    margin-top: -60px;  
  }
  30% {
    margin-top: -90px;  
  }
  40% {
    margin-top: -120px;  
  }
  50% {
    margin-top: -150px;  
  }
  60% {
    margin-top: -180px;  
  }
  70% {
    margin-top: -210px;  
  }
  80% {
    margin-top: -240px;  
  }
  90% {
    margin-top: -270px;  
  }
  100% {
    margin-top: -0px;  
  }
}