.image-grid {
  font-family: futuraBkBt;
  overflow: hidden;
  height: 240px;
  width: 100%;
  position: relative;
  padding-top: 5px;

  > img {
    position: absolute;
    left: 7px;
    top: 0px;
    z-index: 1;
    height: 30px;
  }

  &__container {
    height: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }

    &-full {
      border-top-left-radius: 100px;
    }

    &-double {
      height: 100%;
      display: flex;
      align-items: flex-start;

      .one {
        height: 100%;
        flex: 0.65;
        margin-right: 5px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .two {
        height: 100%;
        flex: 0.35;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    &-triple {
      height: 100%;
      display: flex;
      align-items: flex-start;

      .first {
        height: 100%;
        flex: 0.65;
        margin-right: 5px;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .last {
        flex: 0.35;

        div {
          height: 120px !important;
          overflow: hidden;

          img {
            height: 100%;
          }

          &.one {
            margin-bottom: 5px;
          }

          &.two {
            height: 100%;
            width: 100%;
            margin-top: 5px;
            display: grid;
            place-items: center;
            color: white;
            background-color: rgba(0, 0, 0, 0.55);
            transition: all 0.2s;

            p {
              font-size: 16px;
              margin-bottom: 7px;
              margin-left: -3px;
            }

            &:active,
            &:focus {
              background-color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }
  }

  &__share {
    input {
      display: none;
    }

    &-icon {
      position: absolute;
      right: 15px;
      top: 17px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 999px;
      background: rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      vertical-align: middle;
      transition: all 0.2s;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__count {
    position: absolute;
    right: 15px;
    bottom: 15px;
    display: grid;
    place-items: center;
    height: 47px;
    width: 47px;
    border-radius: 999px;
    color: white;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    vertical-align: middle;
    padding: 10px;

    p {
      margin-bottom: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99999999;
    background-color: rgba(0, 0, 0, 0.95);
    backdrop-filter: blur(1px);

    > img {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 999;
    }

    > div {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      ul {
        &.slider {
          align-items: center;
        }

        &.thumbs {
          display: table;

          .thumb {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
