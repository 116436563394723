.rd-confirm {
  z-index: 99999999 !important;
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1px);
  display: grid;
  place-items: center;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  .rd-confirm__container {
    width: 250px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .rd-confirm__title {
      display: grid;
      grid-template-columns: auto auto auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      text-align: center;
      padding: 10px 15px;

      p {
        margin-bottom: 0;
      }

      h3 {
        font-family: futuraBkBt;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 0;
      }

      .close {
        margin-left: auto;
        // text-align: right;

        img {
          // padding-right: 15px;
        }
      }
    }

    .rd-confirm__body {
      padding: 15px;
      text-align: center;

      p {
        white-space: pre-wrap;
        color: #1c1c1c;
        font-family: futuraBkBt;
        font-size: 16px;
      }
    }

    .rd-confirm__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        flex: 1;
        border-color: rgba(0, 0, 0, 0.05) !important;
        border-radius: 0 !important;
        padding: 10px !important;
        border-bottom: 0 !important;
        font-family: futuraBkBt !important;
        font-weight: bold !important;

        &.btn-cancel {
          color: #e04025 !important;
          border-left: 0 !important;
        }

        &.btn-confirm {
          border-left: 0 !important;
          border-right: 0 !important;
          color: #1c2c4a !important;
        }
      }
    }

    &.rd-confirm__container--width {
      width: fit-content !important;
    }
  }
}
