.search-listing {
  padding: 10px;
  position: relative;
  font-family: futuraBkBt, serif;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-listing__container {
    flex: 1;
    background: #ffffff;
    height: 40px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    transition: all 0.2s;
    border: 1px solid rgba(0, 0, 0, 0.1);

    .form-control {
      padding: unset !important;
      font-size: 14px !important;
    }

    &:focus-within {
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
      border-color: transparent;

      .search-listing__action {
        right: 15px;
        z-index: 99;
        opacity: 0.85;
        background-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 0),
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 1)
        );
      }
    }

    img {
      padding: 0 5px;
      opacity: 0.75;
    }

    .ant-select-selector {
      box-shadow: none !important;
      border-radius: 999px;
      background: inherit;
    }

    input {
      flex: 1;
      box-shadow: none !important;
      background: none;
      border: none;
      border-radius: 0 !important;
      outline: none;
      padding: 0 10px !important;
    }

    .search-listing__action {
      border: none;
      outline: none;
      background-color: inherit;
      border-radius: 999px;
      position: absolute;
      right: 10px;
      opacity: 0;
      z-index: -1;
      transition: all 0.2s;
      padding: 0;
      margin: 0;

      img {
        padding: 5px;
        width: 30px;
      }
    }
  }
}
