.checkout {
  font-family: futuraBkBt;
  color: #1c1c1c;

  .checkout__body {
    padding: 65px 15px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    .checkout__card {
      h3 {
        color: #1c2c4a;
        font-size: 16px;
        font-family: futuraMdBt;
        margin-bottom: 10px;
      }

      .checkout__in-out {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          margin-bottom: 3px;
        }

        .in-out {
          text-align: right;

          p {
            font-weight: bold;
          }
        }
      }

      > p {
        margin-top: 10px;
        font-weight: bold;
        color: #4d71b6;
      }
    }

    .checkout__cancellation {
      h3 {
        color: #1c2c4a;
        font-size: 16px;
        font-family: futuraMdBt;
        margin-bottom: 15px;
      }

      p {
        display: flex;
        padding: 12px;
        border-radius: 10px;

        &.blue {
          background-color: #cce6ed;
        }

        &.red {
          background-color: #f8d7da;
        }
      }
    }

    .checkout__total {
      h3 {
        color: #1c2c4a;
        font-size: 16px;
        font-family: futuraMdBt;
        margin-bottom: 15px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          width: 190px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;

          &.price {
            width: unset;
            white-space: unset;
            overflow: unset;
            text-overflow: unset;
            text-align: right;
            color: #1c2c4a;
            font-weight: bold;
          }
        }
      }
    }

    .checkout__info {
      padding: 0 5px;
      padding-top: 25px;

      .info {
        padding-bottom: 15px;

        p {
          color: #1c2c4a;
          font-size: 16px;
          font-family: futuraMdBt;
          margin-bottom: 10px;
        }
      }
    }

    .checkout__data {
      padding: 15px 0;
      display: flex;
      flex-direction: column;

      // label {
      //   font-family: futuraMdBt;

      //   p {
      //     margin-bottom: 5px;
      //   }
      // }

      // input {
      //   margin-bottom: 10px;
      // }

      > label,
      span {
        font-family: futuraMdBt;
        margin-bottom: 10px;
      }

      > input,
      > div,
      > p {
        margin: 0;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        width: 100%;
        padding: 12px 16px;
        transition: all 0.2s;
        margin-bottom: 15px;
      }

      > div {
        padding: 0;
        border: 0;

        .form-control {
          height: unset !important;
          width: 100% !important;
          padding: 12px 15px 12px 60px !important;
          border-radius: 15px !important;
        }

        .flag-dropdown {
          padding: 7px !important;
          border-radius: 15px 0 0 15px !important;
        }

        .ant-picker-input {
          padding: 10px;
          border-radius: 15px;
          padding: 12px 16px;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .ant-picker {
          padding: 0 !important;
          width: 100% !important;
          border: none !important;
          box-shadow: none !important;
        }

        .nationality-input,
        .language-input {
          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.1) !important;
          border-radius: 15px;
          width: 100%;
          padding: 12px 16px;
          transition: all 0.2s;

          .ant-select-selector {
            padding: 0 !important;
            box-shadow: none !important;
          }

          .ant-select-selection-item {
            background-color: rgba(204, 230, 237, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 !important;
            margin-right: 5px !important;
            border: none !important;
            border-radius: 10px !important;
            height: 35px !important;
            padding: 0 10px;

            span {
              font-family: futuraBkBt !important;
              font-weight: bold !important;
              margin-bottom: 0 !important;
            }
          }
        }

        .nationality-input .ant-select-selection-item {
          font-family: futuraBkBt !important;
          font-weight: bold !important;
          background-color: unset;
          display: unset;
        }
      }

      > p {
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.05);
      }

      > input:focus {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
        border-color: transparent;
      }
    }

    .checkout__extra {
      padding: 10px 5px;

      p {
        display: flex;
        align-items: center;

        img {
          padding-right: 10px;
        }
      }
    }

    .checkout__disclose {
      color: #1c2c4a;

      p {
        margin-bottom: 0;

        a {
          font-weight: bold;
          color: #1c2c4a !important;
          text-decoration: underline !important;
        }
      }
    }

    .checkout__button {
      padding-top: 25px;
      margin-bottom: -15px;
    }
  }
}
