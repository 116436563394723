.page-header {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(10px);
    border-bottom: 1px solid #50505030;
    a {
        width: 44px;
        padding: 8px;
    }
}