.parking {
  color: #1c1c1c;
  font-family: futuraBkBt;
  padding: 75px 0;

  .parking__list {
    padding: 20px 15px;

    .prk-list {
      padding: 16px;
      margin-bottom: 20px;
      border-radius: 12px;
      box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1);

      .prk-list__info {
        h2 {
          text-transform: capitalize;
          color: #1c2c4a;
          font-family: futuraMdBt;
          font-size: 18px;
          margin-bottom: 7px;
          width: 160px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > p {
          margin-bottom: 7px;
          color: #5e5e5e;
          display: flex;
          align-items: center;

          img {
            padding-right: 7px;
          }

          span b {
            color: #1c2c4a;
          }
        }

        h6.prk-loc {
          font-family: futuraBkBt;
          font-size: 14px;
          color: #5e5e5e;
          margin: 10px 0;
          width: 160px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .prk-list__img {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        height: 125px;
        border-radius: 12px;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .prk-list__features {
        margin-top: 5px;
        display: flex;
        align-items: center;

        p {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          margin-right: 10px;

          img {
            margin-right: 5px;
          }
        }
      }

      .prk-button {
        padding: 5px 25px !important;
        font-family: futuraMdBt !important;
      }

      .prk-price {
        color: #1c2c4a;
        font-family: Montserrat;
        font-weight: bold;
        font-size: 20px;

        p {
          padding-right: 7px;
        }
      }
    }
  }
}
