.custom_card_img-text {
    position: relative;

    .custom_card_text {
        width: 90%;
        height: 70%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }
    .custom_card_text_listings {
        width: 85%;
        height: 90%;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;

        ul {
            list-style: unset;
        }
    }
}
.our_story_card {
    position: relative;
    margin-top: 70px;

    .abcc {
        width: 90%;
        margin: auto;
        padding: 20px !important;
        display: flex;
        align-items: center;
        position: absolute;
        top: -43px;
        left: 0;
        right: 0;
    }
}
.our_story_card:last-child {
    margin-bottom: 30px;
}
.circleBackGround {
    background-image: url('../../images/halfCircle.png');
    background-repeat: no-repeat;
    background-size: cover;
    
}
.icon_gallery {
    height: 566px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;

    .icon_gallery_body {
        flex: 1;

        div {
            height: 160px;
        }

        .icon_gallery_img {
            width: 92.94px;
            height: 92.94px;
        }
    }
}