#root, .App, .c-container {
    height: 100%;
    .c-container {
        padding: 60px 0px;
        .c-body {
            height: 100%;
            overflow: auto;
        }
    }
}
.ant-modal-close:focus {
    outline: none;
}

.link_button {
    color: rgb(94, 169, 255) !important;
    text-decoration: none;
    transition: all 0.2s;

    &:hover,
    &:active,
    &:focus {
        color: rgb(0, 119, 255) !important;
    }
}
