.profile-edit {
  font-family: futuraBkBt;
  color: #1c1c1c;
  padding: 75px 0;
  padding-bottom: 35px;

  .profile-edit__image {
    h4 {
      padding: 0 15px;
      padding-top: 10px;
      margin-bottom: -5px;
      font-family: futuraMdBt;
      font-size: 14px;
    }

    .cross {
      display: grid;
      place-items: center;
      height: 30px;
      width: 30px;
      border-radius: 999px;
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(3px);
      bottom: unset;
      right: 18px;
      top: 4px;
    }

    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      border-radius: 10px;
    }

    .ant-upload-picture-card-wrapper {
      .ant-upload-list-item {
        border: none;
      }

      .ant-upload-list-item-thumbnail {
        border-radius: 10px !important;
        opacity: 1 !important;
        // border: 1px solid rgba(0, 0, 0, 0.1) !important;
        overflow: hidden !important;
      }

      .anticon-eye {
        display: none;
      }
    }

    .profile-edit__upload {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: grid;
      place-items: center;
      height: 100px;
      width: 105px;

      .profile-edit__file {
        display: none;
      }

      .edit-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 20px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .profile-edit__body {
    padding: 0 15px;

    .profile-edit__info {
      margin-bottom: 15px;

      > label,
      span {
        font-family: futuraMdBt;
        margin-bottom: 10px;
      }

      > input,
      > div,
      > p {
        margin: 0;
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        width: 100%;
        padding: 12px 16px;
        transition: all 0.2s;
      }

      > div {
        padding: 0;
        border: 0;

        .form-control {
          height: unset !important;
          width: 100% !important;
          padding: 12px 15px 12px 60px !important;
          border-radius: 15px !important;
        }

        .flag-dropdown {
          padding: 7px !important;
          border-radius: 15px 0 0 15px !important;
        }

        .ant-picker-input {
          padding: 10px;
          border-radius: 15px;
          padding: 12px 16px;
          border: 1px solid rgba(0, 0, 0, 0.1);

          &:focus-within {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
            border-color: transparent;
          }
        }

        .ant-picker {
          padding: 0 !important;
          width: 100% !important;
          border: none !important;
          box-shadow: none !important;
        }

        .nationality-input,
        .language-input {
          outline: none;
          border: 1px solid rgba(0, 0, 0, 0.1) !important;
          border-radius: 15px;
          width: 100%;
          padding: 12px 16px;
          transition: all 0.2s;

          .ant-select-selector {
            padding: 0 !important;
            box-shadow: none !important;
          }

          .ant-select-selection-item {
            background-color: rgba(204, 230, 237, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 !important;
            margin-right: 5px !important;
            border: none !important;
            border-radius: 10px !important;
            height: 35px !important;
            padding: 0 10px;

            span {
              font-family: futuraBkBt !important;
              font-weight: bold !important;
              margin-bottom: 0 !important;
            }
          }
        }

        .nationality-input .ant-select-selection-item {
          font-family: futuraBkBt !important;
          font-weight: bold !important;
          background-color: unset;
          display: unset;
        }
      }

      > p {
        margin-top: 10px;
        background-color: rgba(0, 0, 0, 0.05);
      }

      > input:focus {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
        border-color: transparent;
      }
    }
  }

  .profile-edit__extra {
    label {
      font-family: futuraMdBt;
      margin-bottom: 10px;
    }

    .input-radio-custom {
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
      border-radius: 999px;
      width: 65px;
      height: 65px;
      display: grid;
      place-items: center;
      margin-bottom: 10px;

      img {
        width: 25px !important;
      }
    }
  }

  .profile-edit__update {
    padding: 20px 15px;

    button {
      width: 100%;
    }
  }
}
