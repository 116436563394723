.blog {
  font-family: futuraBkBt;
  color: #1c1c1c;
  padding: 55px 0;
  padding-bottom: 70px;

  &__img {
    position: relative;

    .back {
      position: absolute;
      top: 10px;
      left: 8px;
      z-index: 1;

      img {
        width: 25px;
        height: 25px;
        object-fit: cotnain;
      }
    }

    img {
      height: 240px;
      width: 100%;
    }
  }

  &__body {
    &-title {
      background-color: #f5fafb;
      padding: 15px;

      span {
        color: rgba(94, 94, 94, 0.75);
      }

      h1 {
        font-size: 22px;
        font-family: futuraMdBt;
        color: #1c2c4a;
        padding: 5px 0;
      }
    }

    &-content {
      padding: 15px;
      font-size: 16px;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: futuraBkBt;
        font-weight: bold;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  &__recommend {
    h2 {
      font-family: futuraMdBt;
      font-weight: bold;
      font-size: 20px;
      color: #1c2c4a;
      padding: 15px;
      padding-top: 20px;
    }
  }

  &__suggests {
    display: flex;
    align-items: flex-start;
    padding: 10px 15px;

    .blog__suggest {
      flex: 0.5;
      padding: 5px;

      &:last-child {
        margin-right: 0;
      }

      &-img {
        width: 100%;
        height: 115px;
        overflow: hidden;
        margin-bottom: 10px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-body {
        h3 {
          font-family: futuraMdBt;
          font-size: 20px;
          color: #1c2c4a;
          margin-bottom: 5px;
          width: 155px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          font-size: 14px;
          color: #5e5e5e;
          margin-bottom: 5px;
        }
      }
    }
  }
}
