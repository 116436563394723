.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  z-index: 2;
  font-family: serif;
  // background: white;

  // -webkit-backdrop-filter: blur(10px);
  // backdrop-filter: blur(10px);

  .navbar-brand {
    padding: 0;
    vertical-align: -webkit-baseline-middle !important;

    .icon {
      border-radius: 50%;
      display: inline-block;
      position: relative;
      overflow: hidden;
      text-align: center;
    }

    .icon-44 {
      height: 44px;
      width: 44px;
      line-height: 40px;
    }
  }

  .screen-title {
    font-family: futuraMdBt;
    font-size: 18px;
  }
}

.nav-item img {
  width: 20px !important;
}

.screen-header {
  background: #f5fafb;
}
