.chat-screen {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  // padding-top: 65px;
  color: #1c1c1c;
}

.chat-screen__header-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px !important;
  color: #1c1c1c;
}

.chat-screen__header-details {
  flex: 1;
  padding: 0 10px;
  color: #1c1c1c;

  p {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 215px;
    font-weight: 400;
    margin-bottom: 0;
  }

  h5 {
    font-weight: 500;
    margin-bottom: 0;
    font-size: 18px;
  }
}

.chat-screen__listing-header {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  .chat-screen__listing-info {
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.1) !important;
    position: fixed;
    top: -75px;
    height: 105px;
    overflow: hidden;
    flex-direction: row !important;
    width: 90%;
    z-index: -999;
    opacity: 0;
    transition: all 0.3s ease-in-out;

    .listing-info__img {
      background-position: center center;
      background-size: cover;

      img {
        opacity: 0;
        width: 100%;
        object-fit: cover;
      }
    }

    .listing-info__info {
      font-family: futuraBkBt;
      padding-top: 10px;
      padding-bottom: 10px;

      h3 {
        color: #1c2c4a;
        font-size: 14px;
        font-family: futuraMdBt;
        margin-bottom: 5px;
      }

      > p {
        width: 175px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #5e5e5e;
        font-size: 12px;
        // margin-bottom: 10px;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;

        p {
          font-size: 12px;
          margin-bottom: 0;

          span {
            font-weight: bold;
            color: #1c2c4a;
          }
        }

        button {
          font-size: 12px !important;
          padding: 2px 20px !important;
          font-weight: bold !important;
        }
      }
    }

    &.chat-screen__listing-info--show {
      top: 75px;
      opacity: 1;
      z-index: 999;
    }
  }
}

.chat-screen__messages {
  flex: 1;
  padding: 85px 15px 70px;
  background-color: #cddce0;
  //background-image: linear-gradient(to right, lightgrey, lightblue);
  
  // > div {
  //   display: flex;
  //   flex-direction: column-reverse;
  // }
}
