.entire-home {
  font-family: futuraBkBt, sans-serif;

  .search-listing__actions {
    .ant-select-selector {
      width: 125px !important;
      height: 40px !important;
      padding-top: 3px !important;
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      border-radius: 999px !important;
    }
  }
  
  .ant-picker-range {
    border-radius: 11px !important;
  }

  .ant-picker-panels {
    flex-direction: column;
  }

  .entire-home__body {
    padding-top: 65px;
  }

  .entire-home__date {
    padding: 15px 10px;
    background-image: url("../../icons/RectangleStrip.svg");

    .ant-picker {
      z-index: 2;
      border-radius: 999px;

      &.ant-picker-disabled {
        opacity: 0.9;
        background: #fff !important;
        border-color: #d9d9d9 !important;
      }

      .ant-picker-clear {
        display: none;
      }
    }

    .guest {
      padding: 5px 10px;
      background: white;
      border: 1px solid #dfe0e0;
      border-radius: 999px;
    }
  }

  .entire-home__listing {
    padding: 15px 0;

    .entire-home__list {
      margin-bottom: 35px;

      .entire-home__carousel {
        height: 250px !important;
        overflow: hidden;

        img {
          object-fit: cover;
          height: 250px;
        }
      }

      .entire-home__list-body {
        padding: 15px;
        padding-bottom: 5px;

        .entire-home__top {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          h2 {
            font-family: futuraMdBt, sans-serif;
            font-size: 20px;
            color: #1c2c4a;
            margin-bottom: 5px;
            width: 333px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .entire-home__star {
            margin-bottom: 5px;

            img {
              padding-right: 5px;
              width: 20px;
              object-fit: contain;
            }
          }

          .entire-home__reviews {
            text-align: right;

            span {
              padding: 7px 15px;
              font-family: futuraMdBt, sans-serif;
              font-weight: bold;
              color: #1c2c4a;
              border-radius: 20px;
              background: #cce6ed;
            }

            p {
              margin-top: 5px;
              color: #1c1c1c;
              text-decoration: underline;
            }
          }
        }

        p {
          font-size: 16px;
          color: grey;
          margin-bottom: 5px;
        }

        .entire-home__info {
          display: flex;
          align-items: center;

          div {
            padding: 5px 10px;
            border: 1px solid #e8e9ed;
            border-radius: 100px;
            margin-right: 7px;

            img {
              width: 15px;
              margin-right: 5px;
            }

            span {
              color: #5e5e5e;
            }
          }
        }
      }

      .entire-home__list-footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: -3px;
        padding: 0 15px;

        .entire-home__pricing {
          color: grey;

          .entire-home__room-type {
            width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: futuraMdbt, sans-serif;
            font-weight: bold;
            color: #1c2c4a;
          }

          p {
            margin-bottom: 3px;
          }
        }

        .entire-home__price {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          margin-bottom: 3px;

          span {
            font-weight: bold;
            font-size: 16px;
            color: #1c2c4a;
          }
        }
      }
    }
  }
  .loc-info {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    padding-bottom: 7px;

    div {
      display: flex;
      flex: 0.5 !important;

      ul {
        width: 100% !important;
      }

      button {
        width: 100%;
      }
    }

    .lists {
      display: flex;
      flex-wrap: wrap;

      .loc-list {
        margin-right: 5px;
        width: fit-content;
        border: 1px solid #4d71b6;
        display: flex;
        align-items: center;
        padding: 0px 10px !important;
        color: #1c1c1c;
        margin-top: 5px;
        margin-right: 5px;
        cursor: pointer;
        background: rgba(77, 113, 182, 0.15);
        border-radius: 25px;
      }
    }
  }
}
