.add-ons {
  .ant-select-selector {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 999px !important;

    .ant-select-selection-item {
      padding-left: 5px !important;
    }
  }

  .text-darkBlue {
    border-radius: 999px !important;
  }
}
