.payment-history__top {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 15px 0;
  //margin-top: 60px;

  .top--title {
    font-family: futuraMdBt;
    font-size: 16px;
  }

  .top--void {
    align-items: center;
  }
}

.payment-history {
  padding: 75px 15px;
  padding-bottom: 25px;
  //width: 100%;
  align-items: center;
  font-family: futuraBkBt;

  &__body {
    padding: 0 15px;

    &-info {
      padding: 15px 5px;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);

      h3 {
        font-family: futuraMdBt;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
          flex: 0.65;

          &.info {
            flex: 0.35;
            font-family: futuraBkBt;
            font-weight: bold;
          }
        }
      }
    }

    

    .c-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 5px;

      h3 {
        font-family: futuraBkBt;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0;

        span {
          font-weight: normal;
          font-size: 16px;
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    .c-body {
      padding: 0 5px;

      p {
        margin-bottom: 5px;
      }

      &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          flex: 0.5;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
        }
      }
    }

    .c-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // padding: 15px 5px;
      padding: 0 5px;
      padding-bottom: 10px;

      p {
        flex: 0.5;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;

        b {
          font-size: 16px;
        }
      }
    }
  }

  // .table {
  //   overflow-y: auto;
  //   max-width: 100%;
  // }
}
