.ant-picker-range {
  border-radius: 11px !important;
  padding: 5px;
}
.ant-picker-panels {
  flex-direction: column;
}
.filter-checkbox-list,
.hotel-feature-list {
  columns: 2;
}
.ant-collapse-borderless {
  background-color: #ffffff !important;
  border: 0;
}
.guest {
  padding: 5px 5px;
  background: white;
  border: 1px solid #dfe0e0;
  border-radius: 11px;
}
.button-counter {
  width: 20px;
  height: 20px;
}
.bottom-navigation {
  position: fixed;
  z-index: 99999;
  bottom: 0px;
  width: 100%;
  padding: 10px;
}
