.filter-buttons {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  white-space: nowrap;
  overflow-x: auto;
  padding: 5px 10px;
  padding-bottom: 10px;

  // -ms-overflow-style: none;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   display: none;
  // }

  button {
    width: fit-content;
    outline: none !important;
    background-color: white;
    border: 1px solid #5e5e5e;
    border-radius: 200px;
    padding: 3px 8px;
    margin: 0 3px;
    display: flex;
    align-items: center;
    transition: all 0.2s;

    &.filter-selected {
      color: #1c2c4a;
      border-color: #cce6ed;
      background-color: #cce6ed;

      // .filter-title {
      //   padding: 3px 2px;
      // }

      .filter-count {
        font-weight: bold;
      }

      .filter-cross {
        margin-top: -2px;
      }
    }

    &.danger {
      // background-color: #e04025;
      // box-shadow: 2px 2px 8px rgba(224, 64, 37, 0.15);
      border-color: #e04025;
      color: #e04025;

      &:active,
      &:focus {
        color: white !important;
        border-color: #e04025 !important;
        background-color: #e04025 !important;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:active,
    &:focus {
      color: #1c2c4a;
      border-color: #cce6ed;
      background-color: #cce6ed;
    }

    img {
      width: 11px;
      margin: 0 3px;
    }

    span {
      margin: 0 3px;
      font-size: 14px !important;
    }
  }
}
.filter-Am {
  background: #ffffff;
  border: 1px solid #a2a2a2;
  box-sizing: border-box;
  border-radius: 11px;

  img {
    height: 20px;
    width: 20px;
  }
}
