.forget-pass {
  height: 100%;
  width: 100%;
  font-family: futuraBkBt, sans-serif;

  .forget-pass__dots--top {
    position: absolute;
    top: -10px;
    right: 5px;
  }

  .forget-pass__caption {
    color: gray;
    text-align: center;
    padding-top: 35px;
    padding: 0 20px;
  }

  .forget-pass__body {
    text-align: center;
    padding: 0 20px;
    padding-top: 75px;

    .forget-pass__image {
      margin-bottom: 15px;
    }

    h1 {
      font-family: Montserrat, sans-serif;
      font-weight: 900;
      font-size: 22px;
      line-height: 32px;
      color: #1c2c4a;
      text-align: center;
    }

    .forget-pass__form {
      padding: 12px 0;

      .forget-pass__labels {
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-size: 16px;
          font-family: futuraMdBt, sans-serif;
          line-height: 20.5px;
          color: #1c1c1c;
          margin-bottom: 0;
        }

        a {
          color: #4d71b6 !important;
        }
      }

      .forget-pass__input {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 15px;
        width: 100%;
        padding: 12px 16px;
        margin: 7px 0;
        transition: all 0.2s;

        &:focus {
          box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1) !important;
          border-color: transparent;
        }
      }

      .forget-pass__button {
        margin-top: 25px;
        margin-bottom: 15px;
        font-family: futuraMdBt, sans-serif;
        font-size: 16px;
        width: 100%;
        padding: 10px;
        text-transform: inherit !important;
        background-color: #cce6ed;
        color: #1c2c4a;
        box-shadow: 2px 2px 8px #cce6ed;
        border-radius: 999px;
      }
    }

    .forget-pass__cancel {
      font-family: futuraMdBt, sans-serif;
      font-size: 16px;
      width: 100%;
      padding: 10px;
      text-transform: inherit !important;
      background-color: white;
      color: #1c2c4a;
      box-shadow: 2px 2px 8px #cce6ed;
      border: 2px solid #cce6ed;
      border-radius: 999px;
    }
  }

  .forget-pass__dots--bottom {
    position: absolute;
    bottom: -5px;
    left: 0;
  }
}
